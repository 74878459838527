
import { Pagination } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-bootstrap";
import { toast } from "react-toastify";
import { KTSVG } from "../../../_metronic/helpers";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { UsersListPagination } from "../../modules/apps/user-management/users-list/components/pagination/UsersListPagination";
import { HeaderModels } from "../managementModels/HeaderModels";
import SearchInput from "../SearchInput";
import ItemVilles from "./ItemVilles";
import ModalAddVilles from "./ModalAddVilles";

const ListVilles = () => {
  // const [pays, setPays] = useState<any>([]);
  const [villes, setVilles] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const getVilles = () => {
    fetch("https://api.machinery.fcpo.ma/api/villes.jsonld?page="+currentPage)
    .then((res) => res.json())
    .then((data) =>{
  
      setVilles(data['hydra:member']);
      setTotalItems(data['hydra:totalItems']);
      setTotalPages(Math.ceil(totalItems / 5)); // assuming 3 items per page  
      setLoading(false)})
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getVilles();
  }, [currentPage,totalPages]);

  
  const deleteVille = (id: number) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce pays !")) {
      axios
        .delete("https://api.machinery.fcpo.ma/api/villes/" + id)
        .then(() => {
          const filteredpays = villes.filter(
            (ville: any) => ville.id !== id
          );
          setVilles(filteredpays);
          toast.success("ville supprimée");
        })  
        .catch((error) => {
          console.error(error);
        });
    }
  };
  function handlePageChange(event:any, value:any) {
    setCurrentPage(value);
  }
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Villes",
      path: "/pages/managementGlobal/listvilles",
      isSeparator: false,
      isActive: false,
    },
  ];
  const searching = (query: string) => {
    if(query===""){
     
      getVilles()

    }else{
      setLoading(true);
      axios
        .get(
          "https://api.machinery.fcpo.ma/api/villes.json?ville=" + query
        )
        .then((response) => {
          console.log(response.data);
            setVilles(response.data)
            setLoading(false);
        });
    }
      
  };
  return (
    <>
      <HeaderModels />
      <PageTitle breadcrumbs={usersBreadcrumbs}>liste Villes </PageTitle>
      <ToastContainer />

      <div className={`card }`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
            <SearchInput placeholder="Ville" searching={searching}/>
            </span>
          </h3>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a user"
          >
            <button
              type="button"
              className="btn btn-sm btn-primary "
              data-toggle="modal"
              data-target="#modaladdpays"
            >
              <KTSVG
                path="/media/icons/duotune/general/gen035.svg"
                className="svg-icon-2"
              />
              Nouvelle Ville
            </button>
            <ModalAddVilles />
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="card-body py-3">
            {/* begin::Table container */}
            <div className="table-responsive">
              {/* begin::Table */}
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                {/* begin::Table head */}
                <thead>
                  <tr className="fw-bold text-muted">
                    <th className="min-w-150px">Ville</th>
                    {/* <th className="min-w-150px">Pays</th> */}
                    <th className="min-w-150px">code Postale</th>
                    <th className="min-w-100px text-end">Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {villes.map((ville: any, index: any) => {
                    return <ItemVilles key={index} data={ville}  deleteVille={deleteVille}
                    />;
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>   
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  );
};

export default ListVilles;
