import React,{useState,useEffect} from 'react'
import { HeaderItem } from '../../../Components/componenetsPubDown/HeaderItem'
import { Header } from '../../../Components/componenetsPubDown/Header'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import WidgetsPage from '../../modules/widgets/WidgetsPage'
import WizardsPage from '../../modules/wizards/WizardsPage'
import { Feeds } from '../../modules/widgets/components/Feeds'
import { Mixed } from '../../modules/widgets/components/Mixed'
import { MixedWidget7 } from '../../../_metronic/partials/widgets'
import ItemPubDown from '../../../Components/componenetsPubDown/ItemPubDown'
import axios from 'axios'
import { toast,ToastContainer } from 'react-toastify'
import { log } from 'console'
import ItemPubRight from '../../../Components/componentsPubRight/ItemPubRight'
import { Loading } from '../Loading'

export default function ListPubRight() {


  
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Gestion Publicités',
      path: '/pages/managementmachines/listmachine',
      isSeparator: false,
      isActive: false,
    }
  ]
  const [pub2, setPub2] = useState([])
const [loading, setLoading] = useState(true)
  const deletePub = (id: number) => {
    console.log(id);
    
    if (window.confirm("voulez-vous vraimenet supprimer cet utilisateur !"+id)) {
      const filteredpub1 = pub2.filter((pub: any) => pub.id !== id);
          setPub2(filteredpub1);    
            toast.success("pub supprimé");

      axios
        .delete("https://api.machinery.fcpo.ma/api/pub2s/" + id)
        .then(() => {
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };
  const getPub2=()=>{
    axios.get("https://api.machinery.fcpo.ma/api/pub2s.json").then(
      (response)=>{
     
        
             setPub2(response.data)
             setLoading(false)
      }
    )
  }
  useEffect(() => {
    getPub2()
  }, [])
  
  return (
    <>
    <ToastContainer/>
    <Header/>
        <PageTitle breadcrumbs={usersBreadcrumbs}>List Pub</PageTitle>
        <div className='row g-5 g-xl-8'>
        {/* begin::Col */}
    
       {
        loading?<Loading/>:

        pub2.map((pub,index)=>{
          return      <div className='col-xl-4'><ItemPubRight
          deletePub={deletePub} 
          key={index}
          data={pub}
          className='card-xl-stretch mb-xl-8'
          chartColor=''
          chartHeight='200px'
        />  </div> 
        })
       }
         
      
        
      </div>
    </>
  )
}
