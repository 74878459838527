/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import ModalAddCategory from "./ModalAddUser";
import "../../css/stylelistcategories.css";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import { HiOutlineMail, HiLocationMarker } from "react-icons/hi";
import {
  BsFillCalendar2DateFill,
  BsTelephoneForward,
  BsFillTelephoneFill,
} from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { DropDownActions } from "../managementmachines/DropDownActions";
import { DropDownActionsUsers } from "./DropDownActionsUsers";
import { TbView360 } from "react-icons/tb";
type Props = {
  className: string;
  data: any;
  deleteUser: (id: number) => void;
};

const UserItem: React.FC<Props> = ({ className, data, deleteUser }) => {
  const [email, setEmail] = useState(data.email);
  const [password, setPassword] = useState<string>(data.password);
  const [date, setDate] = useState<any>(
    moment(data.dateCreation).format("YYYY-MM-DD")
  );


  

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-header border-0 pt-9'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px bg-light'>
          <img src="/media/avatars/300-1.jpg" alt="" />
                      </div>
                      <div className="d-block">
                         <Link
                  to={"/pages/managementusers/detailsuser/" + data.id}
                  className="text-gray-800 text-hover-primary  fs-6 fw-bold ml-3"
                >
                  {(data.name + " " + data.fullname).toUpperCase()}
                </Link>
                {data.roles.map((role: any, index: any) => {
                  let badgeColor = "";
                  let roleuser="";
                  if (role === "ROLE_CLIENT") {
                    badgeColor =
                      "badge badge-light-info fw-bolder me-auto px-2 py-3";
                      roleuser="CLIENT";
                  } else if (role === "ROLE_ADMIN") {
                    badgeColor =
                      "badge badge-light-success fw-bolder me-auto px-2 py-3";
                      roleuser="ADMIN";

                  } else {
                    badgeColor =
                      "badge badge-light-primary fw-bolder me-auto px-2 py-3";
                      roleuser="FOURNISSEUR";

                  }
                  return (

                     <div    key={index} className='card-toolbar ml-3'>
                     <span className={badgeColor}>
                       {roleuser}
                     </span>
                   </div>
                  );
                })}
                      </div>
         
          </div>
     
       
          <DropDownActionsUsers id={data.id} role={data.roles}/>
      </div>
      <div className="card-body pb-0" style={{paddingTop: "inherit"}} >
        {/* begin::Header */}
        <div className="d-flex align-items-center mb-5" style={{fontSize:"14px"}}>
          {/* begin::User */}
          <div className="d-flex align-items-center flex-grow-1">
            {/* begin::Avatar */}
          
            {/* end::Avatar */}

            {/* begin::Info */}

            <div className="d-flex flex-column w-100" style={{    marginLeft: "64px"}}>
         
              <span className="text-gray-600 fw-semibold">
                <BsFillTelephoneFill /> &ensp;
                {data.tel.replace(/\B(?=(\d{2})+(?!\d))/g, " ")}{" "}
              </span>
              <span className="text-gray-600 fw-semibold">
                <MdEmail /> &ensp;{data.email}{" "}
              </span>
              <span className="text-gray-600 fw-semibold">
                {
                  data.pays?<> <img
                  src={"https://api.machinery.fcpo.ma" + data.pays.imageurl}
                  style={{ width: "30px", height: "15px", borderRadius: "2px" }}
                />
                &ensp;
                {data.pays.pays} , {data.ville.ville}</>:""

                }
              </span>
             
            </div>
            {/* end::Info */}
          </div>
          {/* end::User */}

          {/* begin::Menu */}

          {/* <div className="my-0">
          <DropDownActionUser deleteuser={deleteUser}  data={data.id} />
          </div> */}
          {/* end::Menu */}
        </div>
        {/* end::Header */}

        {/* begin::Post */}
        <div className="mb-5">
          {/* begin::Text */}
          {/* <p className="text-gray-800 fw-normal mb-5 text-justify">
            {data.description.split(" ").slice(0, 29).join(" ")}...
          </p> */}
          {/* end::Text */}

          {/* begin::Toolbar */}
          {/* <div className='d-flex align-items-center mb-5'>
          <a
            href='#'
            className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
          >
            <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
            120
          </a>

          <a
            href='#'
            className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
          >
            <KTSVG path='/media/icons/duotune/general/gen030.svg' className='svg-icon-2' />
            15
          </a>
        </div> */}
          {/* end::Toolbar */}
        </div>
        {/* end::Post */}

        {/* begin::Separator */}
        <div className="separator mb-4"></div>
        {/* end::Separator */}

        <div className="d-flex">
          {" "}
          
          <Link
            to={"#"}
            onClick={() => deleteUser(data.id)}
            title={"supprimer"}
          >
            <KTSVG
              path="/media/icons/duotune/general/reddelete.svg"
              className="svg-icon-3 "
            />
          </Link>
          <span className="text-gray-500 fw-semibold  text-end w-100 d-block mb-4">
            <BsFillCalendar2DateFill />
            &ensp;
            {date}
          </span>
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

export { UserItem };
