import React, { ChangeEvent, useRef, useState, useEffect } from "react";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { HeaderMachine } from "./HeaderMachine";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const FormUpdateMachine = () => {
  let { id } = useParams();
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Machine Management',
      path: '/pages/managementmachines/listmachine',
      isSeparator: false,
      isActive: false,
    }

  ]
  const initialValues = {
    titre: "",
    description: "",
    categorie: "",
    model: "",
    annee: "",
    puissance: "",
    prix: "",
    pays: "",
    ville: "",
    etat: "",
    slug: "",
  };

  const [imagess, setImagess] = useState([]);
  const [loading, setLoading] = useState(true);
  const [titre, setTitre] = useState("");
  const [description, setDescription] = useState("");
  const [slug, setSlug] = useState("");
  const [updateloding, setUpdateLoading] = useState(false);
  const [puissance, setPuissance] = useState("");
  const [prix, setPrix] = useState("");
  const [etat, setEtat] = useState("");
  const [idCategeorie, setIdCategorie] = useState("");
  const [idFournisseur, setIdFournisseur] = useState("");
  const [idModel, setIdModel] = useState("");
  const [idville, setIdVille] = useState("");
  const [idPay, setIdPay] = useState("");
  const [annee, setAnnee] = useState("");

  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [providers, setProviders] = useState([])

  const [filess, setFiles] = useState<File[]>([]);
  const [marques, setMarques] = useState([]);
  const [categories, setCategories] = useState([]);
  const [models, setModels] = useState([]);
  const [idMarque, setidMarque] = useState<any>();
  const [idPays, setidPays] = useState();

  const getCitiesByCountry = (country: any) => {
    axios
      .get(`https://api.machinery.fcpo.ma/api/getcitiesbycountry/${country}.json`)
      .then((response) => {
        setCities(response.data);
        setidPays(country);
      })

      .catch((error) => {
        console.error(error);
      });
  };
  const getCountries = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/pays/all")
      .then((response) => {
        setCountries(response.data);
      })

      .catch((error) => {
        console.error(error);
      });
  };
  const getModel = (value: any) => {
    console.log(value);
    
    axios
      .get(
        "https://api.machinery.fcpo.ma/api/getmodel/api/marques/" +
          value +
          ".json"
      )
      .then((response) => {
        setModels(response.data);
      })

      .catch((error) => {
        console.error(error);
      });
  };
  const getDesignation = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/categories.json")
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getMarques = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/marques.json")
      .then((response) => {
        setMarques(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getdetailMachine = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/machines/" + id + ".json")
      .then((response) => {
        setTitre(response.data.titre);
        setDescription(response.data.description);
        setImagess(response.data.images);
        setEtat(response.data.etat);
        setPrix(response.data.prix);
        setSlug(response.data.slug);
        setidMarque(response.data.marque.id);
        setIdCategorie(response.data.category.id);
        setIdVille(response.data.ville.id);
        setIdPay(response.data.pays.id);
        setAnnee(response.data.annee);
        setPuissance(response.data.puissance);
        setIdFournisseur(response.data.fournisseur.id)
        setIdModel(response.data.model.id)
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getProviders = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/users/byroles/ROLE_FOURNISSEUR")
      .then((response) => {
        setProviders(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

 
  const handelSumbit = () => {
    {
      
      setUpdateLoading(true)
      const formdata = new FormData();
      for (let i = 0; i < filess.length; i++) {
        formdata.append("images[]", filess[i]);
      }

      formdata.append("titre", titre);
      formdata.append("marque", idMarque);
      formdata.append("model", idModel);
      formdata.append("description", description);
      // formdata.append("fournisseur", values["fournisseur"]);
      formdata.append("categorie", idCategeorie);
      formdata.append("puissance", puissance);
      formdata.append("etat", etat);
      formdata.append("pays", idPay);
      formdata.append("ville", idville);
      formdata.append("prix", prix);
      formdata.append("annee", annee);
      formdata.append("slug", slug);
      formdata.append("fournisseur",idFournisseur);


      axios
        .post("https://api.machinery.fcpo.ma/api/machines/" + id, formdata)
        .then((response) => {
          setUpdateLoading(false);
          toast.success(response.data);
        })
        .catch((error) => {
          setUpdateLoading(false)
          toast.error(error.response.data.detail);
        
        });
    }
  };


  useEffect(() => {
    getdetailMachine();
    getMarques();
    getDesignation();
    getProviders();
    getCountries();

  }, []);

  useEffect(() => {
    getModel(idMarque);
  }, [idMarque]);
  useEffect(() => {
    getCitiesByCountry(idPay);
  }, [idPay]);
  
  return (
    <>
      <HeaderMachine searching={()=>{}} show={false}/>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Modifier Mahcine </PageTitle>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <Formik initialValues={initialValues} onSubmit={() => handelSumbit()}>
          {() => (
          <><ToastContainer />
                <Form className="mx-5">
                <label htmlFor="exampleInputEmail1">Photo :</label>
                <div className="form-group mb-5  ">
                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                    style={{
                      backgroundImage:
                        "url(/assets/media/svg/avatars/blank.svg)",
                    }}
                  >
                    <div className="d-flex">
                      {filess.length == 0
                        ? imagess.map((image: any, index) => {
                            return (
                              <div
                                key={index}
                                id="image"
                                className="image-input-wrapper w-125px h-125px mr-2 "
                                style={{
                                  backgroundImage:
                                    "url('https://api.machinery.fcpo.ma/" +
                                    image.imageurl +
                                    "')",
                                }}
                              ></div>
                            );
                          })
                        : filess.map((file) => {
                            return (
                              <div
                                id="image"
                                className="image-input-wrapper w-125px h-125px"
                                style={{
                                  backgroundImage:
                                    "url(" + URL.createObjectURL(file) + ")",
                                }}
                              ></div>
                            );
                          })}
                    </div>

                    <label
                      className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Change avatar"
                    >
                      <i className="bi bi-pencil-fill fs-7"></i>

                      <input
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          const { files } = e.target;
                          if (files) {
                            setFiles(Array.from(files));
                          }
                        }}
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                        multiple
                      />

                      <input type="hidden" name="avatar_remove" />
                    </label>

                    <span
                      className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="cancel"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Cancel avatar"
                    >
                      <i className="bi bi-x fs-2"></i>
                    </span>
                  </div>
                </div>

                <div className="form-group mb-5  ">
                  <label className="mb-1" htmlFor="titre">
                    Titre de Machine :
                  </label>
                  <input
                    onChange={(e: any) => setTitre(e.target.value)}
                    value={titre}
                    type="text"
                    className="form-control"
                    name="titre"
                    id="titre"
                    placeholder="Titre de Machine"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  >
                    Description :
                  </label>
                  <textarea
                    value={description}
                    onChange={(e: any) => setDescription(e.target.value)}
                    className="form-control"
                    name="description"
                    id="exampleFormControlTextarea1"
                    rows={4}
                    placeholder="Description"
                  />
                </div>
                <div className="form-group mb-5   ">
                    <label className="mb-1" htmlFor="categorie">
                      Fournisseur :
                    </label>
                <select
                      name="fournisseur"
                      id="markselect"
                      className="form-select "
                      onChange={(e) => setIdFournisseur(e.target.value)}
                    >
                                            <option value="">Fournisseur</option>

                      {providers.map((provider: any, index) => {

                        return (
                          <option
                            key={index}
                            value={provider.id}
                            selected={
                              provider.id === idFournisseur ? true:  false
                            }
                          >
                            {provider.name} {provider.fullname}
                          </option>
                        );
                      })}
                    </select></div>
                <div className="row">
                  <div className="form-group mb-5  col ">
                    <label className="mb-1" htmlFor="categorie">
                      Catégorie :
                    </label>
                    <select
                      name="categorie"
                      id="markselect"
                      className="form-select"
                      onChange={(e) => setIdCategorie(e.target.value)}
                    >
                      {categories.map((categorie: any, index) => {
                        return (
                          <option
                            key={index}
                            value={categorie.id}
                            selected={
                              categorie.id == idCategeorie ? true : false
                            }
                          >
                            {categorie.titre}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group mb-5 col ">
                    <label className="mb-1" htmlFor="inputCity">
                      Selectionner la Marque :
                    </label>
                    <select
                      name="marque"
                      id="markselect"
                      className="form-select"
                      onChange={(e: any) => {
                        getModel(e.target.value);
                      }}
                    >
                      {marques.map((marque: any, index) => {
                        return (
                          <option
                            key={index}
                            value={marque.id}
                            selected={marque.id == idMarque ? true : false}
                          >
                            {marque.marque}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group mb-5 col ">
                    <label className="mb-1" htmlFor="inputCity">
                      Selectionner Model :
                    </label>
                    <select
                      name="model"
                      id="modelselect"
                      className="form-select"
                      onChange={(e) => setIdModel(e.target.value)}

                    >
                      {models.map((model: any, index) => {
                        return (
                          <option
                            key={index}
                            value={model.id}
                            selected={model.id == idModel ? true : false}
                          >
                            {model.model}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-5 col">
                    <label className="mb-1" htmlFor="inputAddress">
                      Année model :
                    </label>
                    <input
                      value={annee}
                      onChange={(e) => setAnnee(e.target.value)}
                      type="number"
                      name="annee"
                      className="form-control"
                      id="inputAddress"
                      placeholder="Année model"
                    />
                  </div>
                  <div className="form-group mb-5 col">
                    <label className="mb-1" htmlFor="inputAddress2">
                      Puissance :
                    </label>
                    <input
                      value={puissance}
                      onChange={(e) => setPuissance(e.target.value)}
                      type="text"
                      name="puissance"
                      className="form-control"
                      id="inputAddress2"
                      placeholder="Puissance"
                    />
                  </div>

                  <div className="form-group mb-5 col ">
                    <label className="mb-1" htmlFor="prix">
                      Prix :
                    </label>
                    <input
                      value={prix}
                      onChange={(e) => setPrix(e.target.value)}
                      type="number"
                      name="prix"
                      className="form-control"
                      id="prix"
                      placeholder="Prix  en $"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-5 col">
                    <label className="mb-1" htmlFor="pays">
                      Pays :
                    </label>
                    <select
                      name={"pays"}
                      className="form-select"
                      onChange={(e) => {
                        setSelectedCountry(e.target.value);
                        getCitiesByCountry(e.target.value);
                      }}
                    >
                      <option value="">Select a country</option>
                      {countries.map((country: any, index) => (
                        <option
                          key={index}
                          value={country.id}
                          selected={country.id == idPay ? true : false}
                        >
                          {country.pays}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group mb-5 col">
                    <label className="mb-1" htmlFor="ville">
                      Ville :
                    </label>
                    <select name={"ville"} className="form-select">
                      <option value="">Select a city</option>
                      {cities.map((city: any, index) => (
                        <option
                          key={index}
                          value={city.id}
                          selected={city.id == idville ? true : false}
                        >
                          {city.ville}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-5 col">
                    <label className="mb-1 " htmlFor="etat">
                      Slug:
                    </label>
                    <input
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                      type="text"
                      name="slug"
                      className="form-control"
                      id="etat"
                      placeholder="Slug"
                    />{" "}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-5 col">
                    <label className="mb-1 " htmlFor="etat">
                      Etat de Machine :
                    </label>
                    <input
                      value={etat}
                      type="text"
                      onChange={(e) => setEtat(e.target.value)}
                      name="etat"
                      className="form-control"
                      id="etat"
                      placeholder="Etat de Machine "
                    />{" "}
                  </div>
                </div>
                <div className="d-flex justify-content-end ">
                {updateloding ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    En cours ...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary text-end">
                    Modifier
                  </button>
                )}
              </div>
             
            </Form></>
              
          )}
        </Formik>
      )}
    </>
  );
};

export default FormUpdateMachine;
