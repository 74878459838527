import axios from "axios";
import { Field, Form, Formik } from "formik";
import React, { ChangeEvent, useState, useEffect } from "react";
import { ConnectedAccounts } from "../../modules/accounts/components/settings/cards/ConnectedAccounts";
import { Roles } from "./Roles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useNavigate } from "react-router-dom";
import * as Yup from 'yup'

const ModalAddUser = () => {
  const [image, setImage] = useState<File | any>(null);
  const [file, setFile] = useState<string>("");
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    fullname: "",
    email: "",
    password: "",
    resposnable: "",
    raisonSocial: "",
    adresse: "",
    tel: "",
    roles: [],
    siteWe: "",
    pays:"",
    ville:""
  };
  const phoneRegex = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const validationShema=Yup.object().shape(
    {
     name:Yup.string().required("champs vide !"),
     fullname:Yup.string().required("champs vide !"),
     email: Yup.string().email('Invalide email').required('Required'),
    //  resposnable:Yup.string().required("champs vide !"),
    //  raisonSocial:Yup.string().required("champs vide !"),
    //  adresse:Yup.string().required("champs vide !"),
     password: Yup.string()
     .min(3, 'Minimum 3 symbols')
     .max(50, 'Maximum 50 symbols')
     .required('champs vide !'),
    //  siteWeb:Yup.string().required("champs vide !"),
     tel: Yup.string().matches(phoneRegex, "Invalide numéro de télèphone").required("champs vide !"),
    //  roles:Yup.string().required("choisir un role !"),
     pays:Yup.string().required("champs vide !"),
     ville:Yup.string().required("champs vide !"),
    }
  )
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [idPays, setidPays] = useState();
  const [addloding, setAddLoading] = useState(false);

  const getCitiesByCountry = (country: any) => {
    axios
      .get(
        `https://api.machinery.fcpo.ma/api/getcitiesbycountry/${country}.json`
      )
      .then((response) => {
        setCities(response.data);
        setidPays(country);
      })

      .catch((error) => {
        console.error(error);
      });
  };

  const getCountries = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/pays/all")
      .then((response) => {
        setCountries(response.data);
      })

      .catch((error) => {
        console.error(error);
      });
  };

  const handelSumbit = (values: any) => {
   
    values["pays"]="api/pays/"+selectedCountry
    const { roles } = values;
    const selectedRoles = Array.isArray(roles) ? roles : [roles];
    const data = { ...values, roles: selectedRoles };
    // console.log(data);
    // return
    // setAddLoading(true);

  
    axios
      .post("https://api.machinery.fcpo.ma/api/users", data)
      .then((response) => {
        setAddLoading(false);
        navigate("/pages/managementUsers/listusers");
        toast.success("Utilisateur ajouté ");
      })
      .catch((error) => {
        setAddLoading(false);
        toast.error(error);
      });
  };


  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <ToastContainer />
      <div
        className="modal fade"
        id="modaladduser"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationShema}
            onSubmit={(values: any,{resetForm}) => {
              // console.log("hi");
              
              handelSumbit(values)
              resetForm({values:""})
              
            }}
          >
            {({errors,touched,resetForm}) => (
              <Form className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Ajouter Utilisateur
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div
                  className="modal-body"
                  style={{ height: "28em", overflowX: "scroll" }}
                >
                  <div className="form-group">
                    <label className="required fw-bold fs-6 mb-5">Nom</label>
                    <Field
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      name="name"
                      aria-describedby="text"
                      placeholder="Nom"
                      required
                    />
                       {errors.name && touched.name ? (
                      <div className="text-danger fw-bold">
                       {/* {errors.name} */}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label className="required fw-bold fs-6 mb-5">Prenom</label>
                    <Field
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      name="fullname"
                      aria-describedby="text"
                      placeholder="Prenom"
                      required
                    />
                      {errors.fullname && touched.fullname ? (
                      <div className="text-danger fw-bold">
                       {/* {errors.fullname} */}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label className="required fw-bold fs-6 mb-5">Email</label>
                    <Field
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      name="email"
                      aria-describedby="text"
                      placeholder="Email"
                      required
                    />
                      {errors.email && touched.email ? (
                      <div className="text-danger fw-bold">
                       {/* {errors.email} */}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label className="required fw-bold fs-6 mb-5">
                      Password
                    </label>
                    <Field
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      aria-describedby="text"
                      placeholder="Password"
                      required
                    />
                      {errors.password && touched.password ? (
                      <div className="text-danger fw-bold">
                       {/* {errors.password} */}
                      </div>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="form-group mb-5 col">
                      <div className="form-group">
                        <label className=" fw-bold fs-6 mb-5">
                          Numéro Téléphone :
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          id="tel"
                          name="tel"
                          aria-describedby="text"
                          placeholder="tel"
                          required
                        />
                      </div>
                      {errors.tel && touched.tel ? (
                      <div className="text-danger fw-bold">
                       {/* {errors.tel} */}
                      </div>
                    ) : null}
                    </div>

                    <div className="form-group mb-5 col">
                      {" "}
                      <div className="form-group">
                        <label className=" fw-bold fs-6 mb-5">
                          Raison Social
                        </label>

                        <Field
                          type="text"
                          name="raisonSocial"
                          className="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="text"
                        />
                      </div>
                      {errors.raisonSocial && touched.raisonSocial ? (
                      <div className="text-danger fw-bold">
                       {/* {errors.raisonSocial} */}
                      </div>
                    ) : null}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className=" fw-bold fs-6 mb-5">Adresse :</label>
                    <Field
                      as="textarea"
                      rows={2}
                      name="adresse"
                      placeholder="Adresse"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="text"
                      
                    />
                  </div>
                  <div className="form-group">
                    <label className=" fw-bold fs-6 mb-5">Site Web :</label>
                    <Field
                      type="text"
                      name="siteWeb"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Site Web"

                  
                    />
                      {errors.siteWe && touched.siteWe ? (
                      <div className="text-danger fw-bold">
                       {/* {errors.siteWe} */}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group fw-bold">
                    <label htmlFor="exampleInputEmail1">Responsable :</label>
                    <Field
                      type="text"
                      name="responsable"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="text"
                      placeholder="Responsable"

                    />
                      {errors.raisonSocial && touched.raisonSocial ? (
                      <div className="text-danger fw-bold">
                       {/* {errors.raisonSocial} */}
                      </div>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="form-group mb-5 col ">
                      <label className="mb-1 required fw-bold" htmlFor="pays">
                        Pays :
                      </label>
                      <select
                        value={selectedCountry}
                        name={"pays"}
                        className="form-select"
                        onChange={(e) => {
                          setSelectedCountry(e.target.value);
                          getCitiesByCountry(e.target.value);
                        }}
                        required>
                        <option value="">Selectionner</option>
                        {countries.map((country: any, index) => (
                          <option key={index} value={country.id}>
                            {country.pays}
                          </option>
                        ))}
                      </select>
                     
                    </div>
                    <div className="form-group  mb-5 col">
                      <label className="mb-1 required fw-bold" htmlFor="ville">
                        Ville :
                      </label>
                      <Field as="select" name={"ville"} className="form-select " required>
                        <option value="">Selectionner</option>
                        {cities.map((city: any, index) => (
                          <option key={index} value={"api/villes/" + city.id}>
                            {city.ville}
                          </option>
                        ))}
                      </Field>
                      {errors.ville && touched.ville ? (
                      <div className="text-danger fw-bold">
                       {/* {errors.ville} */}
                      </div>
                    ) : null}
                    </div>
                  </div>
                  <div className="mb-7">
                    {/* begin::Label */}
                    <label className="required fw-bold fs-6 mb-5">Role</label>
                    {/* end::Label */}
                    {/* begin::Roles */}
                    {/* begin::Input row */}
                    <Roles />
                    {errors.roles && touched.roles ? (
                      <div className="text-danger fw-bold">
                       {/* {errors.roles} */}
                      </div>
                    ) : null}
                    {/* end::Input row */}

                    {/* end::Input row */}
                    {/* end::Roles */}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={()=>resetForm({values:""})}
                  >
                      Annuler
                  </button>
                  {addloding ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      En cours ...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Enregister
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ModalAddUser;
