/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import ModalAddModels from './ModalAddModels'
import ModalUpdateModels from './ModalUpdateModels'
import moment from 'moment'
import React, { useState, useEffect, ChangeEvent } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import { Loading } from '../Loading'


type Props = {
  className: string,
  data: any,
  deleteModel:(id:number)=>void


}

const ModelsItem: React.FC<Props> = ({ className, data,deleteModel}) => {
  const [model, setModel] = useState(data.model);
  const [description, setDescription] = useState(data.description)
  const [date, setDate] = useState<any>(moment(data.dateCreation).format('YYYY-MM-DD'))
  const [image, setImage] = useState(data.imageurl)
  const [id, setId] = useState(data.id)
  const [file, setFile] = useState<string>("");
  const [idMrq, setIdMarque] = useState(data.marque.id)
  const [change, setChange] = useState(false)
  const [marque, setMarque] = useState(data.marque.id);
  const [loading, setLoading] = useState(false)


  
  const body = {
    model: model,
    description: description,
    dateCreation: date,
    image: image,
    marque:marque
  }

  const [marques, setMarques] = useState([]);

  
  useEffect(() => {
    
    axios.get("https://api.machinery.fcpo.ma/api/marques.json")
      .then((response) => {
        setMarques(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  

  console.log(marque);
  
  const updateModel = (id: any) => {
    setLoading(true)
    console.log(marque);
    const formData = new FormData();
    formData.append('image', image);
    formData.append("model", body['model'])
    formData.append("description", body['description'])
    formData.append("dateCreation", body['dateCreation'])
    formData.append("marque", marque)
    console.log(body);

    axios.post("https://api.machinery.fcpo.ma/api/models/" + id, formData)

      .then((response) => {
        setLoading(false)
        toast.success("modifié avec succès")

      })
      .catch((error) => {
        setLoading(false)
        toast.error(error)
      });

  }

  return (
    <tr>
      <td>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-45px me-5'>

            {
              change ? <img src={file} alt='' /> : <img src={"https://api.machinery.fcpo.ma" + image} alt='' />

            }
            <label
              className="btn btn-icon btn-circle btn-color-muted  w-25px h-25px bg-body shadow"
              data-kt-image-input-action="change"
              data-bs-toggle="tooltip"
              data-bs-dismiss="click"
              title="Change avatar">
              <i className="bi bi-pencil-fill fs-7 ml-3"></i>


              <input

               
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files && e.target.files[0]) {
                    setFile(URL.createObjectURL(e.target.files[0]));
                    setImage(e.target.files[0]);
                    setChange(true)
                  }
                }}
                type="file" name="image" accept=".png, .jpg, .jpeg" />
            </label>
          </div>
          <div className='d-flex justify-content-start flex-column'>
            <input className='d-none' type="text" value={id} />

            <input className='text-dark fw-bold  d-block fs-6' type="text" value={model} onChange={(e) => {
              setModel(e.target.value)
            }} />

          </div>
        </div>
      </td>
      <td>
        <div className='d-flex align-items-center'>

          <div className='d-flex justify-content-start flex-column'>
            <textarea className='text-dark fw-bold  d-block fs-6' value={description} onChange={
              (e) => setDescription(e.target.value)} />

          </div>
        </div>
      </td>
      <td>
        <select name="marque" id="markselect" className="form-select" onChange={
              (e) => setMarque(e.target.value)}>
          {
            marques.map((marque: any, index) => {
              return <option key={index} value={marque.id} selected={marque.id==idMrq?true:false}>{marque.marque}</option>
            })
          }
        </select>
      </td>

      <td>
        <div className='d-flex align-items-center'>

          <div className='d-flex justify-content-start flex-column'>
            <input className='text-dark fw-bold  d-block fs-6' type="date" value={date} onChange={(e) => setDate(e.target.value)} />

          </div>
        </div>
      </td>
      <td>
        <div className='d-flex justify-content-end flex-shrink-0'>

          <button
            type={'submit'}
            title='save'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => updateModel(id)}
          >
            {
              loading?<Loading/>:<KTSVG
              path='/media/icons/duotune/general/gen005.svg'
              className='svg-icon-3' />
            }

          </button>
          <button
            title='drop'
            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
            onClick={() => deleteModel(id)}
          >
            <KTSVG
              path='/media/icons/duotune/general/gen027.svg'
              className='svg-icon-3'
            />
          </button>

        </div>
      </td>
    </tr>
  )
}

export { ModelsItem }
