import axios from "axios";
import { Field, Form, Formik, validateYupSchema } from "formik";
import React, { ChangeEvent, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { Loading } from "../Loading";

const ModalAddPays = () => {
  const [drapeau, setDrapeau] = useState<File | any>(null);
  const [file, setFile] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const initialValues = {
    pays: "",
  };
  
  const validation=Yup.object().shape({
    pays:Yup.string().max(40, "Trops longue").required("champs vide !"),
  })

  const handelSumbit = (values: any) => {

    setLoading(true);
    const formData = new FormData();
    formData.append("image", drapeau);
    formData.append("pays",values["pays"]);
    axios
      .post("https://api.machinery.fcpo.ma/api/pays", formData)
      .then((response) => {
        toast.success("success");
        setFile("");
        setDrapeau([]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  return (
    <>
      <ToastContainer />
      <div
        className="modal fade"
        id="modaladdpays"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <Formik

            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={(values,{resetForm}) => {
              handelSumbit(values);
              resetForm()
              
            }}
          >
            {({ errors, touched }) => (
              <Form className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Ajouter Pays 
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={()=>setLoading(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                    <label htmlFor="exampleInputEmail1">Drapeau :</label>
                    <div className="form-group mb-5  ">
                      <div
                        className="image-input image-input-outline"
                        data-kt-image-input="true"
                        style={{
                          backgroundImage:
                            "url(/assets/media/svg/avatars/blank.svg)",
                        }}
                      >
                        <div
                          id="image"
                          className="image-input-wrapper w-125px h-125px"
                          style={{
                            backgroundImage:
                              file == "" ? "url('')" : "url(" + file + ")",
                          }}
                        ></div>
                        <label
                          className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          data-bs-dismiss="click"
                          title="Change avatar"
                        >
                          <i className="bi bi-pencil-fill fs-7"></i>

                          <input
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              // const reader = new FileReader();
                              // reader.onload=function(e){
                              //   setUrl(e.target?.result)
                              // }
                              // reader.readAsDataURL(e.target.files[0])
                              if (e.target.files && e.target.files[0]) {
                                setFile(URL.createObjectURL(e.target.files[0]));
                                setDrapeau(e.target.files[0]);
                              } 
                            }}
                            type="file"
                            name="image"
                            accept=".png, .jpg, .jpeg"
                            required
                          />
                          <input type="hidden" name="avatar_remove" />
                        </label>

                        <span
                          className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="cancel"
                          data-bs-toggle="tooltip"
                          data-bs-dismiss="click"
                          title="Cancel avatar"
                        >
                          <i className="bi bi-x fs-2"></i>
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Pays :</label>
                      <Field
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        name="pays"
                        aria-describedby="text"
                        placeholder="Pays"
                      />
                      {errors.pays && touched.pays ? (
                        <div className="text-danger fw-bold">
                          {errors.pays} 
                        </div>
                     ) : null}
                    </div>

                
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>

                  <button type="submit" className="btn btn-primary">
                    {loading ? <Loading /> : "Enregistrer"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ModalAddPays;
