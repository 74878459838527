import axios from "axios";
import React, { ChangeEvent, useState,useEffect} from "react";
import { toast } from "react-toastify";
import { KTSVG } from "../../../_metronic/helpers";
import { Loading } from "../Loading";

type Props = {
  data: any;
  deleteVille:(id:number)=>void
};

const ItemVilles: React.FC<Props> = ({ data,deleteVille }) => {
  const [codePostal, setCodepostal] = useState(data.codePostale);
  const [ville, setVille] = useState(data.ville);
  const [pays, setPays] = useState(data.pays);
  const [loading, setLoading] = useState(false);

console.log(data.ville);

  useEffect(() => {
    setVille(data.ville);
    setCodepostal(data.codePostale);
    console.log(",j");
    
  }, [data]);

  const updateVilles = (id: any) => {
    setLoading(true);

    axios
      .put("https://api.machinery.fcpo.ma/api/villes/" + id,{ville,pays,codePostal} )
      .then((response) => {
        toast.success("hhh");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
        
          <div className="d-flex justify-content-start flex-column">
            <input className="d-none" type="text" value={data.id} />
            <input
              className="text-dark fw-bold  d-block fs-6"
              type="text"
              value={ville}
              onChange={(e) => {
                setVille(e.target.value);
              }}
            />
          </div>
        

        </div>
      </td>
      {/* <td>
      <div className="d-flex justify-content-start flex-column">
            <input
              className="text-dark fw-bold  d-block fs-6"
              type="text"
              value={pays}
              onChange={(e) => {
                setPays(e.target.value);
              }}
            />
          </div>

         
      </td> */}
      <td>
      <div className="d-flex justify-content-start flex-column">
            <input
              className="text-dark fw-bold  d-block fs-6"
              type="number"
              value={codePostal}
              onChange={(e) => {
                setCodepostal(e.target.value);
              }}
            />
          </div>
      </td>
      <td>
        <div className="d-flex justify-content-end flex-shrink-0">
          <button
            type={"submit"}
            title="save"
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            onClick={() => updateVilles(data.id)}
          >
            {loading ? (
              <Loading />
            ) : (
              <KTSVG
                path="/media/icons/duotune/general/gen005.svg"
                className="svg-icon-3"
              />
            )}
          </button>
          <button
            title="drop"
            className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
            onClick={() => deleteVille(data.id)}

          >
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default ItemVilles;
