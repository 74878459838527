import axios from "axios";
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { CategoryItem } from "./CategoryItem";
import { HeaderCategories } from "./HeaderCategories";
import ModalAddCategory from "./ModalAddCategory";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import SearchInput from "../SearchInput";

type Props = {
  className: string;
};

const ListCategory: React.FC<Props> = ({ className }) => {
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Catégories",
      path: "/pages/managementGlobal/listcategories",
      isSeparator: false,
      isActive: false,
    },
  ];

  const [categories, setCategories] = useState([]);
  const [file, setFile] = useState<string>("");
  const [loading, setLoading] = useState(true);



 const getCategories=()=>{
  axios
  .get("https://api.machinery.fcpo.ma/api/categories.json")
  .then((response) => {
    setCategories(response.data);
    setLoading(false);
  })
  .catch((error) => {
    console.error(error);
  });
 } 
  useEffect(() => {
   getCategories()
  }, []);

  const deleteCategorie = (id: number) => {
    if (window.confirm("Voulez-vous vraiment supprimer cette catégorie !")) {
      axios
        .delete("https://api.machinery.fcpo.ma/api/categories/" + id)
        .then(() => {
          const filteredcategories = categories.filter(
            (categorie: any) => categorie.id !== id
          );
          setCategories(filteredcategories);
          toast.success("Catégorie supprimée");
        })  
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const searching = (query: any) => {
    if(query===""){
      setLoading(true);
      getCategories()
      

    }else{
      setLoading(true);
      axios
        .get(
          "https://api.machinery.fcpo.ma/api/categories.json?categorie=" + query
        )
        .then((response) => {
          console.log(response.data);
            setCategories(response.data)
            setLoading(false);
        });
    }
      
  };

  return (
    <div>
      <HeaderCategories />
      <PageTitle breadcrumbs={usersBreadcrumbs}>liste Catégories </PageTitle>

      <ToastContainer />
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
              <SearchInput searching={searching} placeholder="Catégoeie"/>
              
            </span>
          </h3>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a user"
          >
            <button
              type="button"
              className="btn btn-sm btn-primary "
              data-toggle="modal"
              data-target="#modaladdcategorie"
            >
              <KTSVG
                path="/media/icons/duotune/general/gen035.svg"
                className="svg-icon-2"
              />
              Nouvelle catégorie
            </button>

            <ModalAddCategory />
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="card-body py-3">
            {/* begin::Table container */}
            <div className="table-responsive">
              {/* begin::Table */}
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                {/* begin::Table head */}
                <thead>
                  <tr className="fw-bold text-muted">
                    <th className="min-w-150px">Categories</th>
                    <th className="min-w-100px">Description</th>
                    <th className="min-w-100px">Date de Creation</th>
                    <th className="min-w-100px text-end">Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {categories.map((categorie, index) => {
                    return (
                      <CategoryItem
                        deleteCategorie={deleteCategorie}
                        key={index}
                        data={categorie}
                        className=""
                      />
                    );
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </div>
  );
};

export default ListCategory;
