/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

import { DropDownActions } from './DropDownActions'
import { BsFillCalendar2DateFill } from "react-icons/bs";
import { BsTelephoneForward } from "react-icons/bs";
import { GrFavorite } from "react-icons/gr";
import { Link } from 'react-router-dom';
import moment from "moment";

type Props = {
  className: string,
  data:any,
  deletem:(data: number) => void;
}



const MachineItem: React.FC<Props> = ({className,data,deletem}) => {
  
  const datecreation: any = new Date(
    data ? moment(data.dateCreation).format("YYYY-MM-DD") : ""
  ).toDateString();
  return (
    <>           
    <div className={`card ${className}`}  >
      {/* begin::Body */}
      <div className='card-body pb-0'>
        {/* begin::Header */}
        <div className='d-flex align-items-center mb-5'>
          {/* begin::User */}
          <div className='d-flex align-items-center flex-grow-1'>
            {/* begin::Avatar */}
            <div className='symbol symbol-45px me-5'>
              <img src={"https://api.machinery.fcpo.ma/"+ data.images[0].imageurl} alt='' />
            </div>
            {/* end::Avatar */}

            {/* begin::Info */}
            <div className='d-flex flex-column'>
              <Link
             to={"/pages/managementmachines/detailmachine/" + data.id}
            className='text-gray-800 text-hover-primary fs-6 fw-bold'>
               {(data.titre).toUpperCase()}
              </Link>

              <span className='text-danger fw-semibold'>{(data.prix).replace(/\B(?=(\d{3})+(?!\d))/g, " ")} $</span>
              <span className='text-gray-600 fw-semibold'><BsTelephoneForward/> &ensp;{data.fournisseur?(data.fournisseur.tel).replace(/\B(?=(\d{2})+(?!\d))/g, " "):""} </span>

            </div>
            {/* end::Info */}
          </div >
          {/* end::User */}

          {/* begin::Menu */}
          
         
          
          <div className='my-0'>
        
            <DropDownActions deletemachine={deletem}  data={data.id} />
          </div>
          {/* end::Menu */}
        </div>
        {/* end::Header */}

        {/* begin::Post */}
        <div className='mb-5'>
          {/* begin::Text */}
          <p className='text-gray-800 fw-normal mb-5 text-justify'>
           {
            (data.description).split(" ")
            .slice(0, 29)
            .join(" ")
           }...
             </p>
          {/* end::Text */}

          {/* begin::Toolbar */}
          {/* <div className='d-flex align-items-center mb-5'>
            <a
              href='#'
              className='btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2 me-4'
            >
              <KTSVG path='/media/icons/duotune/communication/com012.svg' className='svg-icon-2' />
              120
            </a>

            <a
              href='#'
              className='btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2'
            >
              <KTSVG path='/media/icons/duotune/general/gen030.svg' className='svg-icon-2' />
              15
            </a>
          </div> */}
          {/* end::Toolbar */}
        </div>
        {/* end::Post */}

        {/* begin::Separator */}
        <div className='separator mb-4'></div>
        {/* end::Separator */}
        <span className="text-gray-500 fw-semibold  text-end w-100 d-block mb-4">
                  <BsFillCalendar2DateFill />
                  &ensp;
                  {datecreation}
                </span>
      </div>
      {/* end::Body */}
    </div></>
    
  )
}

export {MachineItem}
