/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field } from 'formik'
import React, {useState} from 'react'
import { toAbsoluteUrl } from '../../../_metronic/helpers'


const Roles: React.FC = () => {
  const [data, setData] = useState()



  const [loading, setLoading] = useState(false)

  const click = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  return (
    <div className='card mb-5 mb-xl-10'>
     

      <div id='kt_account_connected_accounts' className='collapse show'>
        <div className='card-body border-top p-9'>
       

          <div className='py-2'>
            <div className='d-flex flex-stack'>
              <div className='d-flex'>


                <div className='d-flex flex-column'>
                  <a href='#' className='fs-5 text-dark text-hover-primary '>
                    Admin
                  </a>
                  {/* <div className='fs-6 fw-bold text-gray-400'>Plan properly your workflow</div> */}
                </div>
              </div>
              <div className='d-flex justify-content-end'>
                <div className='form-check form-check-solid form-switch'>
                  <Field
                    
                    name={"roles"}
                    value={"ROLE_ADMIN"}
                    className='form-check-input w-45px h-30px'
                    type='radio'
                    checked="checked"
                  />
                  <label className='form-check-label' htmlFor='googleswitch'></label>
                </div>
              </div>
            </div>

            <div className='separator separator-dashed my-5'></div>

            <div className='d-flex flex-stack'>
              <div className='d-flex'>
                

                <div className='d-flex flex-column'>
                  <a href='#' className='fs-5 text-dark text-hover-primary '>
                    Fournisseur
                  </a>
                  {/* <div className='fs-6 fw-bold text-gray-400'>Keep eye on on your Repositories</div> */}
                </div>
              </div>
              <div className='d-flex justify-content-end'>
                <div className='form-check form-check-solid form-switch'>
                  <Field
                    name={"roles"}
                    value={"ROLE_FOURNISSEUR"}
                    className='form-check-input w-45px h-30px'
                    type='radio'
                    id='githubswitch'
                  
                  />
                  <label className='form-check-label' htmlFor='githubswitch'></label>
                </div>
              </div>
            </div>

            <div className='separator separator-dashed my-5'></div>

            <div className='d-flex flex-stack'>
              <div className='d-flex'>

                <div className='d-flex flex-column'>
                  <a href='#' className='fs-5 text-dark text-hover-primary '>
                    Client
                  </a>
                </div>
              </div>
              <div className='d-flex justify-content-end'>
                <div className='form-check form-check-solid form-switch'>
                  <Field
                    name={"roles"}
                    value={"ROLE_CLIENT"}
                    className='form-check-input w-45px h-30px'
                    type='radio'
                   
                  />
                  <label className='form-check-label' htmlFor='slackswitch'></label>
                </div>
              </div>
            </div>
          </div>
        </div>

      
      </div>
    </div>
  )
}

export {Roles}
