import React from 'react'
import { HeaderMachine } from '../managementmachines/HeaderMachine'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { HeaderItem } from '../../../Components/componenetsPubDown/HeaderItem'
import { Header } from '../../../Components/componenetsPubDown/Header'
import  FormAdd  from '../../../Components/componenetsPubDown/FormAdd'
import { FeedsWidget2, MixedWidget10 } from '../../../_metronic/partials/widgets'
import { Horizontal } from '../../modules/wizards/components/Horizontal'
import FormAddRight from '../../../Components/componentsPubRight/FormAddRight'
import FormAddSlide from '../../../Components/componentsPubSlide/FormAddSlide'


export default function AddPubSlider() {
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Gestion Publicités',
      path: '/pages/managementmachines/listmachine',
      isSeparator: false,
      isActive: false,
    }
  ]
  return (
    <>
        <Header/>
        <PageTitle breadcrumbs={usersBreadcrumbs}>Ajouter Pub</PageTitle> 
        <FormAddSlide/>
    </>
  )
}
