import React from "react";
import { ChangeEvent, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { KTSVG } from "../../../_metronic/helpers";
import { CardMedia } from "@mui/material";
import { AnyObject } from "yup/lib/object";
import { InputFilesUpdate } from "./InputFilesUpdate";
import axios from "axios";
import { toast } from "react-toastify";
import { Loading } from "../Loading";

type Props = {
  detaildata: any;
  update: boolean;
};

const ItemDetalsImages: React.FC<Props> = ({ detaildata, update }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [titre, setTitre] = useState(detaildata.titre);
  const [soustitre, setSoustitre] = useState(detaildata.soustitre);
  const [detail, setDetail] = useState(detaildata.detail);
  const [images, setImages] = useState<string[]>(
    detaildata.imagesMachineDetails
  );
  const [filess, setFiles] = useState<File[]>([]);
  const [outputfile, setOutputfile] = useState<File[]>([]);
  const [loading, setLoading] = useState(false)


  const [checken, setChecken] = useState(true);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  console.log(filess);

  const deleteImage = (id: any) => {
    console.log(id);

    setImages(images.filter((image: any) => image.id !== id));

    axios
      .delete("https://api.machinery.fcpo.ma/api/images_details_delete/" + id)
      .then(() => {
        toast.success("image supprimée");
      });
  };

  const updateDetailsImages = () => {
    setLoading(true)
    console.log("https://api.machinery.fcpo.ma/api/details_images/" + detaildata.id);
    
    const formdata = new FormData();
    formdata.append("titre", titre);
    formdata.append("soustitre", soustitre);
    formdata.append("detail", detail);
    for (let i = 0; i < filess.length; i++) {
      formdata.append("images[]", filess[i]);
    }

    axios
      .post(
        "https://api.machinery.fcpo.ma/api/details_images/" + detaildata.id,
        formdata
      )
      .then((response) => {
          setLoading(false);
          toast.success("bien modifiée")

        // setOutputfile([]);
      })
      .catch((error) => {
        setLoading(false)
        toast.error("une erreur s'est produite")
      });
  };

  return (
    <>
      <Accordion
        expanded={expanded === "panel" + detaildata.id}
        onChange={handleChange("panel" + detaildata.id)}
      >
        <AccordionSummary
          expandIcon={
            <>
              {" "}
              <KTSVG
                path="/media/icons/duotune/general/gen035.svg"
                className="svg-icon-2"
              />
            </>
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {update ? (
              <input
                className="form-control"
                type={"text"}
                value={titre}
                onChange={(e) => {
                  setTitre(e.target.value);
                }}
              />
            ) : (
              <h5> {detaildata.titre}</h5>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="d-flex" >
          <Typography>
            {images.map((image: any) => {
              return update ? (
                <>
                  <InputFilesUpdate deleteimages={deleteImage} image={image} />
                </>
              ) : (
                <CardMedia
                  component="img"
                  sx={{height:"auto" }}
                  image={"https://api.machinery.fcpo.ma" + image.imageurl}
                  alt="Live from space album cover"
                />
              );
            })}
            {update
              ? filess.map((file, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        id="image"
                        className="image-input-wrapper w-125px h-125px"
                        style={{
                          backgroundImage:
                            "url(" + URL.createObjectURL(file) + ")",
                          backgroundSize: "cover",
                        }}
                      />
                      <label
                        className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Change avatar"
                      >
                        <i className="bi bi-pencil-fill fs-7 "></i>

                        <input
                          onChange={(e) => {
                            const { files } = e.target;
                            if (files) {
                              setFiles(Array.from(files));
                            }
                          }}
                          type="file"
                          name="avatar"
                          accept=".png, .jpg, .jpeg"
                        />
                      </label>

                      <label
                        className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Change avatar"
                        onClick={() => {
                          //  deleteImage()
                        }}
                      >
                        <KTSVG
                          path="/media/icons/duotune/general/gen034.svg"
                          className="svg-icon-2"
                        />
                      </label>
                    </>
                  );
                })
              : ""}

            {update ? (
              <label
                className="btn btn-icon btn-color-muted btn-active-color-primary w-50  bg-body shadow"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                data-bs-dismiss="click"
                title="Change avatar"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen035.svg"
                  className="svg-icon-2 ml-2"
                />
                <span className="ml-2"> ajouter image</span>
                <input
                  onChange={(e) => {
                    const { files } = e.target;
                    if (files) {
                      setFiles(Array.from(files));
                      setOutputfile(Array.from(files));
                    }
                  }}
                  type="file"
                  name="avatar"
                  accept=".png, .jpg, .jpeg"
                  multiple
                />
              </label>
            ) : (
              ""
            )}
          </Typography>
          <div className="d-bock" style={{ padding: "12px" }}>
            <Typography>
              {update ? (
                <textarea
                  className="form-control"
                  rows={2}
                  cols={40}
                  value={soustitre}
                  onChange={(e) => {
                    setSoustitre(e.target.value);
                  }}
                />
              ) : (
                <h6>{detaildata.soustitre}</h6>
              )}
            </Typography>
            <Typography>
              {update ? (
                <textarea
                  className="form-control"
                  rows={6}
                  cols={40}
                  value={detail}
                  onChange={(e) => {
                    setDetail(e.target.value);
                  }}
                />
              ) : (
                detaildata.detail
              )}
            </Typography>

            <Typography>
             {
              update? 
              <button
              type="button"
              onClick={updateDetailsImages}
              title="save"
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              // onClick={()=>updateUser(id)}
            >
             {
              loading?<Loading/>: <KTSVG
              path="/media/icons/duotune/general/gen005.svg"
              className="svg-icon-3"
            />
             }
            </button>:""
             }
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
export default ItemDetalsImages;
