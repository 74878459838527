import React from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { Link } from 'react-router-dom'

type Props={
  id:number
  deletePub:(id :number)=>void
}
export const DropDownPubSlide :React.FC<Props> = ({id,deletePub}) => {
  return (
    <div className="btn-group">
        <button
          type="button"
          className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold "
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen024.svg"
            className="svg-icon-2"
          />
        </button>
        <div className="dropdown-menu" style={{left:"-49px"}}>
          {/* <Link
            title={"modifier"}
            to={"/pages/managementmachines/editmachine/"+id }
            className="dropdown-item p-2 text-hover-primary"
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
            <span> modidfier Pub</span>
          </Link> */}
         
          
         

          <Link
            to={"#"}
            title={"supprimer"}
            onClick={() => deletePub(id)}
            className="dropdown-item p-2 text-hover-primary"
          >
            <KTSVG
              // امين
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
            <span > supprimer Pub</span>
          </Link>
        </div>
      </div>
  )
}
