import React, { useEffect, useState } from 'react';
import { ModelsItem } from './ModelsItem';
import { HeaderModels } from './HeaderModels';
import { KTSVG } from '../../../_metronic/helpers';
import ModalAddModels from './ModalAddModels';
import ModalUpdateModels from './ModalUpdateModels';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import SearchInput from '../SearchInput';
const ListModels = () => {
  const [models, setModels] = useState([])
  const [loading, setLoading] = useState(true);
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Models",
      path: "/pages/managementGlobal/list",
      isSeparator: false,
      isActive: false,
    },
  ];


  const getModels=()=>{
    axios.get("https://api.machinery.fcpo.ma/api/models.json")
    .then((response) => {
      setModels(response.data);
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
    });
  }


  console.log(models);
  
  const deleteModel = (id: number) => {
    if (window.confirm("Voulez-vous vraiment supprimer cette catégorie !")) {
      axios
        .delete("https://api.machinery.fcpo.ma/api/models/" + id)
        .then(() => {
          const filteredmodels = models.filter((model: any) => model.id !== id);
          setModels(filteredmodels);
          toast.success("Model supprimé");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };


  
  useEffect(() => {
  getModels()
  }, [])
  const searching = (query: any) => {
    if(query==""){
     
      getModels()

    }else{
      setLoading(true);
      axios
        .get(
          "https://api.machinery.fcpo.ma/api/models.json?model=" + query
        )
        .then((response) => {
          console.log(response.data);
            setModels(response.data)
            setLoading(false);
        });
    }
      
  };



  return (
    <div>  

    <HeaderModels />
    <PageTitle breadcrumbs={usersBreadcrumbs}>liste Modeles </PageTitle>
    <ToastContainer/>

    <div className={`card }`}>

{/* begin::Header */}
<div className='card-header border-0 pt-5'>
  <h3 className='card-title align-items-start flex-column'>
    <span className='card-label fw-bold fs-3 mb-1'> 
      <SearchInput placeholder="Modèle" searching={searching}/>
    </span>
  </h3>
  <div
    className='card-toolbar'
    data-bs-toggle='tooltip'
    data-bs-placement='top'
    data-bs-trigger='hover'
    title='Click to add a user'
  >
  <button
              type="button"
              className="btn btn-sm btn-primary "
              data-toggle="modal"
              data-target="#addmodel"
            >
              <KTSVG
                path="/media/icons/duotune/general/gen035.svg"
                className="svg-icon-2"
              />
              Nouveau Modele
            </button>

      
  <ModalAddModels />  
  <ModalUpdateModels />

  </div>
</div>
{
  loading?
  <div className="d-flex justify-content-center">
  <div className="spinner-border" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
</div>:<div className='card-body py-3'>
  {/* begin::Table container */}
  <div className='table-responsive'>
    {/* begin::Table */}
    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
      {/* begin::Table head */}
      <thead>
        <tr className='fw-bold text-muted'>
        
          <th className='min-w-150px'>Modèles</th>
          <th className='min-w-100px '>Description</th>
          <th className='min-w-150px'>Marque</th>
          <th className='min-w-100px '>Date de Creation</th>
          <th className='min-w-100px text-end'>Actions</th>
        </tr>
      </thead>
      {/* end::Table head */}
      {/* begin::Table body */}
      <tbody>
        {
          models.map((model,index)=>{
               return <ModelsItem className='' deleteModel={deleteModel} key={index} data={model}/>
          })
        }    
    </tbody>
            {/* end::Table body */}
          </table> 
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
}
{/* end::Header */}
{/* begin::Body */}

      {/* begin::Body */}
    
    </div>
    </div>
  )
}

export default ListModels  