import * as React from "react";

import ItemDetalsImages from "./ItemDetalsImages";

type Props = {
  details: [],
  update:boolean

};
const DetailsImages: React.FC<Props> = ({ details,update }) => {
 

  return (
    <div>
      {details.map((detail:any, index) => {
        return (
          <ItemDetalsImages  update={update} detaildata={detail} key={index}/>
        );
      })}
    </div>
  );
};
export default DetailsImages;
