import React, { ChangeEvent, useRef, useState, useEffect } from "react";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HeaderUsers } from "./HeaderUsers";
import { MultipleSelectChip } from "./MultipleSelectChip";
import { BiArrowBack } from "react-icons/bi";
import * as Yup from "yup";

const DetailsUser = () => {
  let { id } = useParams();

  const initialValues = {
    nom: "",
    prenom: "",
    email: "",
    password: "",
    resposnable: "",
    raisonSocial: "",
    adresse: "",
    tel: "",
    roles: "",
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [raisonsocial, setRaisonsocial] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [roles, setRoles] = useState<string[]>([]);
  const [ville, setVille] = useState("");
  const [siteWeb, setSiteWeb] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  
  const [pays, setPays] = useState("");
  const [updateloding, setUpdateLoading] = useState(false);
  const [idPays, setidPays] = useState();
  const [rolesSelected, setrolesSelected] = useState<string[]>([]);

  const [formErrors, setFormErrors] = useState<any>({});

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Des Utilisateurs",
      path: "/pages/managementUsers/listusers",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "liste  Utilisateurs",
      path: "/pages/managementUsers/listusers",
      isSeparator: false,
      isActive: false,
    },
  ];


  const getRoleSeleceted = (roles: string[]) => {
    setrolesSelected(roles);
  };
  const getCitiesByCountry = (country: any) => {
    axios
      .get(
        `https://api.machinery.fcpo.ma/api/getcitiesbycountry/${country}.json`
      )
      .then((response) => {
        setCities(response.data);
        setidPays(country);
      })

      .catch((error) => {
        console.error(error);
      });
  };

  const getCountries = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/pays/all")
      .then((response) => {
        setCountries(response.data);
      })

      .catch((error) => {
        console.error(error);
      });
  };

  
  const getdetailUser = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/users/" + id + ".json")
      .then((response) => {
        setNom(response.data.name);
        setPrenom(response.data.fullname);
        setRaisonsocial(response.data.raisonSocial);
        setTel(response.data.tel);
        setEmail(response.data.email);
        setRoles(response.data.roles);
        setSelectedCity(response.data.ville.id)
        setPays(response.data.pays.id)
        setSiteWeb(response.data.siteWeb)
        setVille("api/villes/"+response.data.ville.id)
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getdetailUser();
  }, []);

  useEffect(() => {
    getCountries();
  }, []);
  useEffect(() => {
    getCitiesByCountry(pays);
  }, [pays]);
  const handelSumbit = () => {
    {
      // const phoneRegex = /^(\+33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

      // const errors:any = {};
      // if (!nom) errors.nom = 'Le nom est requis';
      // if (!prenom) errors.prenom = 'Le prénom est requis';
      // if (!selectedCountry) errors.pays = 'Le pays est requis';
      // if (!cities) errors.ville = 'La ville est requise';
      // if (rolesSelected.length === 0) errors.roles = 'Veuillez sélectionner au moins un rôle';
      //   if (!tel  ){errors.tel = 'Le numéro de téléphone est requis'}else if(!phoneRegex.test(tel)){errors.tel = 'Invalide Numéro'} ;
      
      // console.log(errors);
      
      // setFormErrors(errors);
      // if (Object.keys(errors).length === 0) {
      //   setUpdateLoading(true)
     
        const body = {
          name: nom,
          fullname: prenom,
          roles: rolesSelected,
          raisonSocial: raisonsocial,
          email: email,
          tel: tel,
          pays:"api/pays/"+pays,
          ville:ville
        };
     console.log(body);
 setUpdateLoading(true);
       
        axios
          .put("https://api.machinery.fcpo.ma/api/users/" + id, body)
          .then((response) => {
           
            toast.success("utilisateur modifié");
            setUpdateLoading(false);

          })
          .catch((error) => {
            setUpdateLoading(false);
            toast.error(error);
          });      }
  
    
    
  };
  

  return (
    <>
      <HeaderUsers update={true} searchuser={()=>{}}/>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Détails Utilisateur </PageTitle> 
                   <ToastContainer />

      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <Formik initialValues={initialValues} onSubmit={() => handelSumbit()}>
          {() => (
            <>
              <Form className="mx-5">
             
                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative mb-4">
                  <img
                    src="/media/avatars/300-1.jpg"
                    alt="Metornic"
                  />
                </div>

                <div className="form-group mb-5  ">
                  <label
                    className="mb-1 required fw-bold fs-6 '"
                    htmlFor="titre"
                  >
                    Nom :
                  </label>
                  <input
                  required
                    onChange={(e: any) => setNom(e.target.value)}
                    value={nom}
                    type="text"
                    className="form-control"
                    name="nom"
                    id="titre"
                    placeholder="Nom"
                  />
                        {/* {formErrors.nom && <div className="text-danger fw-bold">{formErrors.nom}</div>} */}

                </div>
                <div className="form-group mb-5  ">
                  <label
                    className="mb-1 required fw-bold fs-6 '"
                    htmlFor="titre"
                  >
                    Prenom :
                  </label>
                  <input
                  required
                    onChange={(e: any) => setPrenom(e.target.value)}
                    value={prenom}
                    type="text"
                    className="form-control"
                    name="prenom"
                    id="titre"
                    placeholder="Nom"
                  />
                                            {/* {formErrors.prenom && <div className="text-danger fw-bold">{formErrors.prenom}</div>} */}

                </div>
                <div className="form-group mb-5  ">
                  <label
                    className="mb-1 required fw-bold fs-6 '"
                    htmlFor="titre"
                  >
                    Numéro Télèphone :
                  </label>
                  <input
                  required
                    onChange={(e: any) =>setTel(e.target.value)
                  }
                    value={tel}
                    type="text"
                    className="form-control"
                    name="tel"
                    id="titre"
                    placeholder="Nom"
                  />
                {formErrors.tel && <div className="text-danger fw-bold">{formErrors.tel}</div>}

                </div>
                <div className="form-group mb-5  ">
                  <label
                    className="mb-1 required fw-bold fs-6 '"
                    htmlFor="titre"
                  >
                    Site Web :
                  </label>
                  <input
                    onChange={(e: any) =>setSiteWeb(e.target.value)
                  }
                    value={siteWeb}
                    type="text"
                    className="form-control"
                    name="siteWeb"
                    id="titre"
                    placeholder="site web"
                  />

                </div>
                <div className="row">
                  <div className="form-group mb-5 col">
                    <label
                      className="mb-1 required fw-bold fs-6 '"
                      htmlFor="pays"
                    >
                      Pays :
                    </label>
                    <select
                 
                    required
                      name={"pays"}
                      className="form-select"
                      onChange={(e) => {
                        setSelectedCountry(e.target.value);
                        getCitiesByCountry(e.target.value);
                        setPays(e.target.value)
                      }}
                    >
                      <option value="">Choisir un pays</option>
                      {countries.map((country: any, index) => (
                        <option
                          key={index}
                          value={country.id}
                          selected={country.id == pays ? true : false}
                        >
                          {country.pays}
                        </option>
                      ))}
                    </select>
                    {formErrors.pays && <div className="text-danger fw-bold">{formErrors.pays}</div>}

                  </div>
                  <div className="form-group mb-5 col">
                    <label
                      className="required fw-bold fs-6  mb-1"
                      htmlFor="ville"
                    >
                      Ville :
                    </label>
                    <select
                    required
                     name={"ville"} className="form-select" onChange={(e)=>setVille(e.target.value)}>
                      <option value="">Choisir une ville</option>
                      {cities.map((city: any, index) => (
                        <option
                          key={index}
                          value={"api/villes/"+city.id}
                          selected={city.id == selectedCity ? true : false}
                        >
                          {city.ville}
                        </option>
                      ))}
                    </select>
                    {formErrors.ville && <div className="text-danger fw-bold">{formErrors.ville}</div>}

                  </div>
                </div>
                <div className="mb-7">
                  {/* begin::Label */}
                  <label className="required fw-bold fs-6 mb-5">Role</label>
                  {/* end::Label */}
                  {/* begin::Roles */}
                  {/* begin::Input row */}
                  <MultipleSelectChip
                    rolesSeleceted={getRoleSeleceted}
                    roles={roles}
                  />

                  {/* end::Input row */}

                  {/* end::Input row */}
                  {/* end::Roles */}
                </div>
                {/* {formErrors.roles && <div className="text-danger fw-bold">{formErrors.roles}</div>} */}

                <Link
            to={"/pages/managementUsers/listusers"}
            className="btn btn-primary text-end mt-6"
          >
            <BiArrowBack />
          </Link>
                <div className="d-flex justify-content-end ">
                  {updateloding ? (
                    <button className="btn btn-primary" type="button" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      En cours ...
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary text-end">
                      Modifier
                    </button>
                  )}
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default DetailsUser;
