import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { ChangeEvent, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Loading } from '../../app/pages/Loading';

export default function FormAdd() {
    const [image1, setImage1] = useState<File | any>(null);
    const [file1, setFile1] = useState<string>("");
    const [image2, setImage2] = useState<File | any>(null);
    const [file2, setFile2] = useState<string>("");
    const [image3, setImage3] = useState<File | any>(null);
    const [file3, setFile3] = useState<string>("");
    const [loading, setLoading] = useState(false);

    const initialValues = {
        priorite: "", 
        lien1: "",
        lien2: "",
        lien3: "",
      
      };

      const handelSumbit = (values: any) => 
        {
            setLoading(true);
            const formData = new FormData();
            formData.append("priorite", values["priorite"]);

            formData.append("img1", image1);
            formData.append("img2", image2);
            formData.append("img3", image3);


            formData.append("lien1", values["lien1"]);
            formData.append("lien2", values["lien2"]);
            formData.append("lien3", values["lien3"]);
            console.log(image1,image2,image3,values);
            
           
            axios
              .post("https://api.machinery.fcpo.ma/api/pub1s", formData)
              .then(() => {
                setLoading(false);
                toast.success("pub ajouté");
                setFile1("")
                setFile2("")
                setFile3("")
                // navigate("pages/managementGlobal/listmarques")
                
              })
              .catch((error) => {
                toast.error(error.response.data.detail);
                setLoading(false);
              });
      };

  return (
    <div>
          <ToastContainer />

           <Formik
          initialValues={initialValues}
        //   validationSchema={validationSchema}
         
          onSubmit={(values:any,{resetForm}) => {
            handelSumbit(values);
            resetForm({values:""})
          }}
        >
          {({ touched, errors }) => (
            <Form className="mx-5">
              <div className="row">
                <div className="col-4">
                <label htmlFor="exampleInputEmail1">Image de Pub :</label>
                  <div className="form-group mb-5  ">
                    <div
                      className="image-input image-input-outline"
                      data-kt-image-input="true"
                      style={{
                        backgroundImage:
                          "url(/assets/media/svg/avatars/blank.svg)",
                      }}
                    >
                      <div
                        id="image"
                        className="image-input-wrapper w-125px h-125px"
                        style={{
                          backgroundImage:
                            file1 == "" ? "url('')" : "url(" + file1 + ")",
                        }}
                      ></div>
                      <label
                        className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Change avatar"
                      >
                        <i className="bi bi-pencil-fill fs-7"></i>

                        <input
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            // const reader = new FileReader();
                            // reader.onload=function(e){
                            //   setUrl(e.target?.result)
                            // }
                            // reader.readAsDataURL(e.target.files[0])
                            if (e.target.files && e.target.files[0]) {
                              setFile1(URL.createObjectURL(e.target.files[0]));
                              setImage1(e.target.files[0]);
                            }
                          }}
                          type="file"
                          name="avatar"
                          accept=".png, .jpg, .jpeg"
                        />
                        <input type="hidden" name="avatar_remove" />
                      </label>

                      <span
                        className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Cancel avatar"
                      >
                        <i className="bi bi-x fs-2"></i>
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Lien d'image :</label>
                    <Field
                      type="text"
                      name="lien1"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="text"
                      placeholder="lien "
                    />
                   
                </div>
                </div>
            
               <div className="col-4">
               <label htmlFor="exampleInputEmail1">Image de Pub :</label>
                  <div className="form-group mb-5  ">
                    <div
                      className="image-input image-input-outline"
                      data-kt-image-input="true"
                      style={{
                        backgroundImage:
                          "url(/assets/media/svg/avatars/blank.svg)",
                      }}
                    >
                      <div
                        id="image"
                        className="image-input-wrapper w-125px h-125px"
                        style={{
                          backgroundImage:
                            file2 == "" ? "url('')" : "url(" + file2 + ")",
                        }}
                      ></div>
                      <label
                        className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Change avatar"
                      >
                        <i className="bi bi-pencil-fill fs-7"></i>

                        <input
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            // const reader = new FileReader();
                            // reader.onload=function(e){
                            //   setUrl(e.target?.result)
                            // }
                            // reader.readAsDataURL(e.target.files[0])
                            if (e.target.files && e.target.files[0]) {
                              setFile2(URL.createObjectURL(e.target.files[0]));
                              setImage2(e.target.files[0]);
                            }
                          }}
                          type="file"
                          name="avatar"
                          accept=".png, .jpg, .jpeg"
                        />
                        <input type="hidden" name="avatar_remove" />
                      </label>

                      <span
                        className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Cancel avatar"
                      >
                        <i className="bi bi-x fs-2"></i>
                      </span>
                    </div>
                  </div>
                   <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Lien d'image :</label>
                    <Field
                      type="text"
                      name="lien2"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="text"
                      placeholder="lien "
                    />
                   
                </div>
               </div>
               <div className="col-4">
               <label htmlFor="exampleInputEmail1">Image de Pub :</label>
                  <div className="form-group mb-5  ">
                    <div
                      className="image-input image-input-outline"
                      data-kt-image-input="true"
                      style={{
                        backgroundImage:
                          "url(/assets/media/svg/avatars/blank.svg)",
                      }}
                    >
                      <div
                        id="image"
                        className="image-input-wrapper w-125px h-125px"
                        style={{
                          backgroundImage:
                            file3 == "" ? "url('')" : "url(" + file3 + ")",
                        }}
                      ></div>
                      <label
                        className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Change avatar"
                      >
                        <i className="bi bi-pencil-fill fs-7"></i>

                        <input
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            // const reader = new FileReader();
                            // reader.onload=function(e){
                            //   setUrl(e.target?.result)
                            // }
                            // reader.readAsDataURL(e.target.files[0])
                            if (e.target.files && e.target.files[0]) {
                              setFile3(URL.createObjectURL(e.target.files[0]));
                              setImage3(e.target.files[0]);
                            }
                          }}
                          type="file"
                          name="avatar"
                          accept=".png, .jpg, .jpeg"
                        />
                        <input type="hidden" name="avatar_remove" />
                      </label>

                      <span
                        className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        data-bs-dismiss="click"
                        title="Cancel avatar"
                      >
                        <i className="bi bi-x fs-2"></i>
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Lien d'image :</label>
                    <Field
                      type="text"
                      name="lien3"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="text"
                      placeholder="lien "
                    />
                   
            
               </div>
                </div>
              
                
              </div>
              <div className="row">
              <div className="form-group col-4">
                    <label htmlFor="exampleInputEmail1">Priorité :</label>
                    <Field
                      type="number"
                      name="priorite"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="text"
                      placeholder="priorite "
                    />
                   
                </div>
              </div>

          
              

                
                 
                
                  
                <button type="submit" className="btn btn-primary">
                  {
                    loading?<Loading/>:"Ajouter"
                  }
                </button>
                </Form> 
        
         
          )}
        </Formik>
    </div>
  )
}
