import React from 'react'
import { HeaderMachine } from '../managementmachines/HeaderMachine'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { HeaderItem } from '../../../Components/componenetsPubDown/HeaderItem'
import { Header } from '../../../Components/componenetsPubDown/Header'
import  FormAdd  from '../../../Components/componenetsPubDown/FormAdd'


export default function AddPubDown() {
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Gestion Publicités',
      path: '/pages/managementmachines/listmachine',
      isSeparator: false,
      isActive: false,
    }
  ]
  return (
    <>
        <Header/>
        <PageTitle breadcrumbs={usersBreadcrumbs}>Ajouter Pub</PageTitle> 
        <FormAdd/>
    </>
  )
}
