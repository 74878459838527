import axios from "axios";
import { Field, Form, Formik, validateYupSchema } from "formik";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { Loading } from "../Loading";

const ModalAddVilles = () => {
  const [pays, setPays] = useState([]);

  const [loading, setLoading] = useState(false);

  const initialValues = {
    ville: "",
    codePostale: "",
    pays: "",
  };

  const handelSumbit = (values: any) => {
    console.log(values);
    setLoading(true);

    axios
      .post("https://api.machinery.fcpo.ma/api/villes", values)
      .then((response) => {
        toast.success("success");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    axios.get("https://api.machinery.fcpo.ma/api/pays/all").then((resposne) => {
      console.log(resposne.data);
      setPays(resposne.data);
    });
  }, []);

  return (
    <>
      <ToastContainer />
      <div
        className="modal fade"
        id="modaladdpays"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <Formik
            initialValues={initialValues}
            // validationSchema={validationSchema}
            onSubmit={(values) => {
              handelSumbit(values);
            }}
          >
            {({ errors, touched }) => (
              <Form className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Ajouter Ville
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setLoading(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Ville :</label>
                    <Field
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      name="ville"
                      aria-describedby="text"
                      placeholder="ville"
                    />
                    {/* {errors.titre && touched.titre ? (
                        <div className="text-danger fw-bold">
                          {errors.titre} */}
                    {/* </div> */}
                    {/* ) : null} */}
                  </div>
                  <div className="form-group">
                    <label htmlFor="markselect">Pays :</label>
                    <Field
                      as="select"
                      name="pays"
                      id="markselect"
                      class="form-select"
                    >
                      <option value="">Pays</option>
                      {pays.map((pay: any, index) => {
                        return (
                          <option key={index} value={"/api/pays/" + pay.id}>
                            <img
                              src={
                                "https://api.machinery.fcpo.ma" + pay.imageurl
                              }
                              style={{
                                width: "30px",
                                height: "18px",
                                borderRadius: "2px",
                              }}
                            />{" "}
                            {pay.pays}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">code Postal :</label>
                    <Field
                      type="number"
                      className="form-control"
                      id="exampleInputEmail1"
                      name="codePostale"
                      aria-describedby="text"
                      placeholder="code Postal"
                    />
                    {/* {errors.titre && touched.titre ? (
                        <div className="text-danger fw-bold">
                          {errors.titre} */}
                    {/* </div> */}
                    {/* ) : null} */}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>

                  <button type="submit" className="btn btn-primary">
                    {loading ? <Loading /> : "Enregistrer"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ModalAddVilles;
