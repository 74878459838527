/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ChangeEvent, useState} from 'react'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import moment from 'moment'
import { ToastContainer, toast } from "react-toastify";
import ModalAddCategory from './ModalAddMark'; 
import ModalUpdateCategory from './ModalUpdatMark'
import axios from 'axios';


type Props = {
  className: string
  data:any,
  deleteMarque:(id:number)=>void
}

const MarkItem: React.FC<Props> = ({className,data,deleteMarque}) => {
  const [marque, setMarque] = useState(data.marque);
  const [description, setDescription] = useState(data.description)
  const [date, setDate] = useState<any>(moment(data.dateCreation).format('YYYY-MM-DD'))
  const [image, setImage] = useState(data.imageurl)
  const [id, setId] = useState(data.id)
  const [file,setFile]=useState<string>("");
  const [change, setChange] = useState(false)
  const [loading, setLoading] = useState(false);


  const updateMarque=(id:any)=>{
     setLoading(true)
    const body={
      marque:marque,
      description:description,
      dateCreation:date,
      image:image
    }
    const formData = new FormData();
    formData.append('image', image);
    formData.append("marque",body['marque'])
    formData.append("description",body['description'])
    formData.append("dateCreation",body['dateCreation'])
      console.log(body);
    axios.post("https://api.machinery.fcpo.ma/api/updatemarques/"+id,formData)
  
      .then((response) => {
        setLoading(false)
        toast.success(response.data)

      })
      .catch((error) => {
        console.error(error);
      });
  
  }

  return (

  
              <tr>
                  <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-45px me-5'>

                    {
                        change ? <img src={file} alt=''/>:<img src={"https://api.machinery.fcpo.ma"+image} alt='' />

                    }  
                      <label 
                className="btn btn-icon btn-circle btn-color-muted  w-25px h-25px bg-body shadow"
                data-kt-image-input-action="change"
                data-bs-toggle="tooltip"
                data-bs-dismiss="click"
                title="Change avatar">
                            <i className="bi bi-pencil-fill fs-7 ml-3"></i>


                      <input

                  
                      onChange={(e:ChangeEvent<HTMLInputElement>)=>{
                        if(e.target.files && e.target.files[0]) {
                              setFile(URL.createObjectURL(e.target.files[0]));
                              setImage(e.target.files[0]);
                              setChange(true)
                        }
                  }}
                   type="file" name="image"  accept=".png, .jpg, .jpeg" />
                      </label>
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                    <input className='d-none'type="text" value={id}  />

                      <input className='text-dark fw-bold  d-block fs-6'type="text" value={marque}  onChange={(e)=>{                        
                          setMarque(e.target.value)
                      }}/>
                    
                    </div>
                  </div>
                </td>
                <td>
                <div className='d-flex align-items-center'>
                
                    <div className='d-flex justify-content-start flex-column'>
                      <textarea className='text-dark fw-bold  d-block fs-6' value={description} onChange={
                        (e) => setDescription(e.target.value)} />
                    
                    </div>
                </div>
                </td> 
                <td>
                <div className='d-flex align-items-center'>
                
                <div className='d-flex justify-content-start flex-column'>
                  <input className='text-dark fw-bold  d-block fs-6'type="date" value={date} onChange={(e)=>setDate(e.target.value)}/>
                
                </div>
            </div>
                </td> 
                <td>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                      
                    <button
                      type={'submit'}
                      title='save'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' 
                      onClick={()=>updateMarque(id)}
                    >
                      {
                        loading?<div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>:<KTSVG 
                        path='/media/icons/duotune/general/gen005.svg'
                          className='svg-icon-3' />
                      }
                          
                      
                    </button>
                    <button
                      title='drop'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm' 
                      onClick={() => deleteMarque(id)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen027.svg'
                        className='svg-icon-3'
                      />
                    </button>

                  </div>  
                </td>
              </tr>
            
      
  )
}

export {MarkItem}
