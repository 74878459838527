import React from 'react';
const ModalUpdateModels = () => {
  return (
    <>


<div className="modal fade" id="ModalUpdateCategory" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Update Model</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
          <form>
      <div className="form-group">
        <label htmlFor="exampleInputEmail1"> New Model :</label>
        <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="text" placeholder=" New Model" />
      </div>

      <button type="submit" className="btn btn-primary">Submit</button>
    </form>



      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
    </>
  )
}

export default ModalUpdateModels  