/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { KTSVG } from "../../../helpers";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useAuth } from "../../../../app/modules/auth";

export function AsideMenuMain() {
  const intl = useIntl();
  const { currentUser } = useAuth();

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotune/general/gen025.svg"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
      />

      {/* User */}
      {/* <AsideMenuItemWithSub
        to='/user'
        title='Machines'
        icon='/media/icons/duotune/ecommerce/ecm007.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub> */}

      {/* machines */}

      {currentUser?.roles?.includes("ROLE_ADMIN") ? (
        <>
          <AsideMenuItemWithSub
            to="/pages/managementmachines"
            title=" Gestion Machines "
            icon="/media/icons/duotune/technology\teh005.svg"
          >
            <AsideMenuItem
              to="/pages/managementmachines/ajoutermachine"
              title="Ajouter Machine"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/pages/managementmachines/listmachine"
              title="List Machine"
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to="/pages/managementGlobal"
            title="Gestion Globale"
            icon="/media/icons/duotune/general/gen022.svg"
          >
            <AsideMenuItem
              to="/pages/managementGlobal/listcategories"
              title="Liste Categories"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/pages/managementGlobal/listmarques"
              title="Liste  Marques"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/pages/managementGlobal/listmodels"
              title="Liste  Modèles"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/pages/managementGlobal/listpays"
              title="Liste Pays"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/pages/managementGlobal/listville"
              title="Liste Villes"
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to="/pages/managementUsers"
            title="Gestion Utilisateurs"
            icon="/media/icons/duotune/general/gen049.svg"
          >
            <AsideMenuItem
              to="/pages/managementUsers/listusers"
              title="Liste  Utilisateurs"
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItemWithSub
            to="/pages"
            title="Gestion Publicites "
            icon="/media/icons/duotune/general/gen049.svg"
          >
            <AsideMenuItemWithSub
              to="pages/pub1"
              title="Gestion Publicites Down"
              icon="/media/icons/duotune/general/gen049.svg"
            >
              <AsideMenuItem
                to="pages/pub1/addpub1"
                title="Ajouter une Publicité"
                hasBullet={true}
              />
              <AsideMenuItem
                to="pages/pub1/listpub1"
                title="Liste des Publicités"
                hasBullet={true}
              />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to="/pages/pub2/addpub2"
              title="Gestion Publicites right"
              icon="/media/icons/duotune/general/gen049.svg"
            >
              <AsideMenuItem
                to="/pages/pub2/addpub2"
                title="Ajouter une Publicité"
                hasBullet={true}
              />
              <AsideMenuItem
                to="/pages/pub2/listpub2"
                title="Liste des Publicités"
                hasBullet={true}
              />
            </AsideMenuItemWithSub>
            <AsideMenuItemWithSub
              to="/pages"
              title="Gestion Des Slider-Pub"
              icon="/media/icons/duotune/general/gen049.svg"
            >
              <AsideMenuItem
                to="/pages/pubslide/addslidepub"
                title="Ajouter une Publicité"
                hasBullet={true}
              />
              <AsideMenuItem
                to="/pages/pubslide/listslidepub"
                title="Liste des Publicités"
                hasBullet={true}
              />
             
            </AsideMenuItemWithSub>
          </AsideMenuItemWithSub>
        </>
      ) : (
        <AsideMenuItemWithSub
          to="/pages/managementmachines"
          title=" Gestion Machines "
          icon="/media/icons/duotune/technology\teh005.svg"
        >
          <AsideMenuItem
            to="/pages/managementmachines/ajoutermachine"
            title="Ajouter Machine"
            hasBullet={true}
          />
          <AsideMenuItem
            to="/pages/managementmachines/listmachine"
            title="List Machine"
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}

      {/* Page Error */}

      {/* <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      {/* Title of pages */}

      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}

      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>
    </>
  );
}
