import axios from "axios";
import React, { ChangeEvent, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { HeaderMachine } from "./HeaderMachine";
import { BsFillCalendar2DateFill } from "react-icons/bs";
import Carousel from "react-bootstrap/Carousel";
import { title } from "process";
import { BiArrowBack } from "react-icons/bi";
import "../../../_metronic/assets/css/styleimagesMachines.css";
import DetailsIamges from "./DetailsImages";
import CaracteristiquesMachine from "./CaracteristiquesMachine";
import { Loading } from "../Loading";







const FormDetailMachine = () => {
  let { id } = useParams();
  const [detail, setDetail] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [imagee, setImagee] = useState("");
  const [imagess, setImagess] = useState([]);
  const { formatMessage } = useIntl();
  const datecreation: any = new Date(
    detail ? detail.dateCreation : ""
  ).toDateString();

  const getdetailMachine = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/machines/" + id + ".json")
      .then((response) => {
        setDetail(response.data);
        setImagee(response.data.images[currentImageIndex].imageurl);
        setImagess(response.data.images);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    getdetailMachine();
  }, []);



  const updateMainImage = (id: any) => {
    setCurrentImageIndex(id);
  };

  // const showNextImage = () => {
  //   setCurrentImageIndex((currentIndex) => (currentIndex + 1) % imagess.length);
  // };
  // const showPreviousImage = () => {
  //   setCurrentImageIndex((currentIndex) =>
  //     currentIndex === 0 ? imagess.length - 1 : currentIndex - 1
  //   );
  // };
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Machine Management',
      path: '/pages/managementmachines/listmachine',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '- List Machine',
      path: '/pages/managementmachines/listmachine',
      isSeparator: false,
      isActive: false,
    },
    {
      title: 'Détails Machine',
      path: '/pages/managementmachines/detailmachine/'+id,
      isSeparator: true,
      isActive: false,
    },
  ]
  return (
    <>
      <HeaderMachine searching={()=>{}} show={false} />
     
      <PageTitle breadcrumbs={usersBreadcrumbs}>Détails Machine</PageTitle>

      {loading ? (
        <Loading/>
      ) : (
        <>
          <div className="row">
            <div className={" col-7 images"}>
              <div className={"bigImage"}>
                <img
                  alt=""
                  src={
                    `https://api.machinery.fcpo.ma` +
                    detail.images[currentImageIndex].imageurl
                  }
                  height={"300"}
                />
              </div>
              <div className={"smallImages"}>
                {imagess.map((imageitem: any, index) => {
                  return (
                    <img
                      alt=""
                      key={index}
                      src={`https://api.machinery.fcpo.ma` + imageitem.imageurl}
                      onMouseMove={() => updateMainImage(index)}
                    />
                  );
                })}
              </div>

              <div className="datecreation mt-5">
                <span className="text-gray-500 fw-semibold">
                  <BsFillCalendar2DateFill />
                  &ensp;
                  {datecreation}
                </span>
              </div>

              <div className="price mt-5" style={{fontSize:" 19px"}}>
                <span className="text-danger font-weight-bold">
                  {detail.prix}    &ensp; $
                </span>
              </div>
              {/* <div className={"IconContainerNextImage"} onClick={showNextImage}>
        <img alt="" src="./images/icon-next.svg" className={"nextImage"} />
      </div>
      <div
        className={"IconContainerPreviousImage"}
        onClick={showPreviousImage}
      >
        <img
          alt=""
          src="./images/icon-previous.svg"
          className={"previousImage"}
        />
      </div> */}
            </div>

            <div className="col-lg-4 col-md-5 col-sm-12 designation  ">
              <h3>Caractéristiques :</h3>
            <CaracteristiquesMachine categorie={detail.category.categorie} fournisseur={detail.fournisseur.tel} marque={detail.marque.marque}
              etat={detail.etat} model={detail.model.model} puissance={detail.puissance} ville={detail.ville.ville} pays={detail.pays.pays} drapeau={detail.pays.imageurl} 
              />
            </div>
          </div>

          <div className="title mt-5">
            <h2>{detail.titre.toUpperCase()}</h2>
          </div>

          <div className="description mt-7">
            <p>{detail.description}</p>
          </div>
          <div className="row details">
          <h2>Plus Détails</h2>
          <DetailsIamges  update={false} details={detail.detailsImages}/>

          </div>
               
          <Link
            to={"/pages/managementmachines/listmachine"}
            className="btn btn-primary text-end mt-6"
          
          >
            <BiArrowBack />
          </Link>
        </>
      )}
    </>
  );
};

export default FormDetailMachine;
