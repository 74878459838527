import React from "react";
import { Link } from "react-router-dom";
import { KTSVG } from "../../../_metronic/helpers";
import { Dropdown1, Dropdown2 } from "../../../_metronic/partials";
import { TbWashDryDip } from "react-icons/tb";
type Props = {
  id: number;
  role: any;
};
export const DropDownActionsUsers: React.FC<Props> = ({ id, role }) => {
  return (
    <div>
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold "
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen024.svg"
            className="svg-icon-2"
          />
        </button>
        <div className="dropdown-menu" style={{left:"-49px"}}>
          {role[0] === "ROLE_FOURNISSEUR" ? (
            <>
              <Link to={"/pages/managementmachines/ajoutermachine/"+id} className="dropdown-item p-2 text-hover-primary">
                {" "}
                <KTSVG
                  path="/media/icons/duotune/general/gen035.svg"
                  className="svg-icon-2 p-2"
                />
                <span> ajouter Machine</span>
              </Link>
              <Link to={"/pages/managementmachines/listmachines/fournisseur="+id} className="dropdown-item p-2 text-hover-primary">
              <KTSVG
                  path="/media/icons/duotune/ecommerce/ecm010.svg"
                  className="svg-icon-2 p-2"
                /> 
                voir machines
              </Link>
            </>
          ) : (
            ""
          )}

        
          
          <Link to={"/pages/managementusers/detailsuser/" +id} className="dropdown-item p-2 text-hover-primary" >
          <KTSVG
                  path="/media/icons/duotune/general/gen019.svg"
                  className="svg-icon-3 p-2"
                />
             voir détails 
          </Link>
        </div>
      </div>
    </div>
  );
};
