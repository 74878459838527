import React from 'react'
import { KTSVG } from '../../_metronic/helpers'
import { BiTimeFive } from "react-icons/bi";
import { DropDownPubDown } from './DropDownPubRight';


type Props = {
    className: string
    chartColor: string
    chartHeight: string
    data:any
    deletePub:(id:number)=>void
  }
const ItemPubRight :React.FC<Props> = ({className, chartColor, chartHeight,data,deletePub}) => {
  return (
    <>
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
        {data.title.length > 13
                      ? data.title.slice(0, 13) + ".."
                      : data.title}
          
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
        
          <DropDownPubDown deletePub={deletePub} id={data.id}/>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        <div className='flex-grow-1'>
        <a target='_blank' href={data.lien}>
          <img src={"https://api.machinery.fcpo.ma/"+data.imageurl} alt="" className="w-100" />
          </a>
        </div>

      </div>
      {/* end::Body */}
    </div>
    
    </>
  )
}
export default  ItemPubRight