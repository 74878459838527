import React, { ChangeEvent, useRef, useState, useEffect } from "react";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { HeaderMachine } from "./HeaderMachine";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useNavigate, useParams } from "react-router-dom";
const AjouterMachine = () => {


const {id}= useParams();

  const initialValues = {
    titre: "",
    description: "",
    categorie: "",
    model: "",
    annee: "",
    puissance: "",
    prix: "",
    pays: "",
    ville: "",
    etat: "",
    slug: "",
    fournisseur:undefined,
  };
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [providers, setProviders] = useState([])
  const [filess, setFiles] = useState<File[]>([]);
  const [marques, setMarques] = useState([]);
  const [categories, setCategories] = useState([]);
  const [models, setModels] = useState([]);
  const [idMarque, setidMarque] = useState();
  const [idPays, setidPays] = useState();
  const [addloding, setAddLoading] = useState(false);
 

  const getCitiesByCountry = (country: any) => {
    axios
      .get(`https://api.machinery.fcpo.ma/api/getcitiesbycountry/${country}.json`)
      .then((response) => {
        setCities(response.data);
        setidPays(country);
      })

      .catch((error) => {
        console.error(error);
      });
  };

  const getCountries = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/pays/all")
      .then((response) => {
        setCountries(response.data);
      })

      .catch((error) => {
        console.error(error);
      });
  };

  const getModel = (value: any) => {
    setidMarque(value);
    axios
      .get(
        "https://api.machinery.fcpo.ma/api/models.json?marque=" +
          value 
          
      )
      .then((response) => {
        setModels(response.data);
      })

      .catch((error) => {
        console.error(error);
      });
  };
 
  const getDesignation = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/categories.json")
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getMarques = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/marques.json")
      .then((response) => {
        setMarques(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getProviders = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/users/byroles/ROLE_FOURNISSEUR")
      .then((response) => {
        setProviders(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const handelSumbit = (values: any) => {
    {
      setAddLoading(true);

      values["marque"] = idMarque;
      values["pays"] = idPays;
      
      const formdata = new FormData();
      for (let i = 0; i < filess.length; i++) {
        formdata.append("images[]", filess[i]);
      }
      formdata.append("titre", values["titre"]);
      formdata.append("description", values["description"]);
      formdata.append("marque", values["marque"]);
      formdata.append("model", values["model"]);
      id ? formdata.append("fournisseur",id)  : formdata.append("fournisseur", values["fournisseur"]);
      formdata.append("categorie", values["categorie"]);
      formdata.append("puissance", values["puissance"]);
      formdata.append("etat", values["etat"]);
      formdata.append("pays", values["pays"]);
      formdata.append("ville", values["ville"]);
      formdata.append("prix", values["prix"]);
      formdata.append("annee", values["annee"]);
      formdata.append("slug", values["slug"]);
      axios.post("https://api.machinery.fcpo.ma/api/machines", formdata)
      .then((response) => { 
          setAddLoading(false)
          navigate("/pages/managementmachines/listmachine");
          toast.success("Machine ajoutée ");

      })
      .catch((error) => {
          toast.error(error);
      });
    }
  };

  

  useEffect(() => {
    getDesignation();
  }, []);

  useEffect(() => {
    getMarques();
  }, []);

  useEffect(() => {
    getCountries();
  }, []);
  useEffect(() => {
    getProviders();
  }, []);
  
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Machine Management',
      path: '/pages/managementmachines/listmachine',
      isSeparator: false,
      isActive: false,
    }

  ]
  return (
    <>
      <HeaderMachine searching={()=>{}} show={false}/>
      <PageTitle breadcrumbs={usersBreadcrumbs}>Ajouter Mahcine </PageTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handelSumbit(values)}
      >
        {() => (
         <> 
         <ToastContainer />

             <Form className="mx-5">
              <label htmlFor="exampleInputEmail1">Photo :</label>
              <div className="form-group mb-5  ">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                  style={{
                    backgroundImage: "url(/assets/media/svg/avatars/blank.svg)",
                  }}
                >
                  <div className="d-flex">
                    {filess.length == 0 ? (
                      <div
                        id="image"
                        className="image-input-wrapper w-125px h-125px"
                        style={{ backgroundImage: "url('')" }}
                      ></div>
                    ) : (
                      filess.map((file) => {
                        return (
                          <div
                            id="image"
                            className="image-input-wrapper w-125px h-125px"
                            style={{
                              backgroundImage:
                                "url(" + URL.createObjectURL(file) + ")",
                            }}
                          ></div>
                        );
                      })
                    )}
                  </div>

                  <label
                    className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="change"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    title="Change avatar"
                  >
                    <i className="bi bi-pencil-fill fs-7"></i>

                    <input
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        // const reader = new FileReader();
                        // reader.onload=function(e){
                        //   setUrl(e.target?.result)
                        // }
                        // reader.readAsDataURL(e.target.files[0])
                        const { files } = e.target;
                        if (files) {
                          setFiles(Array.from(files));
                        }
                      }}
                      type="file"
                      name="avatar"
                      accept=".png, .jpg, .jpeg"
                      multiple
                    />

                    <input type="hidden" name="avatar_remove" />
                  </label>

                  <span
                    className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="cancel"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    title="Cancel avatar"
                  >
                    <i className="bi bi-x fs-2"></i>
                  </span>
                </div>
              </div>

              <div className="form-group mb-5  ">
                <label className="mb-1" htmlFor="titre">
                  Titre de Machine :
                </label>
                <Field
                  type="text"
                  className="form-control"
                  name="titre"
                  id="titre"
                  placeholder="Titre de Machine"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Description :
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  name="description"
                  id="exampleFormControlTextarea1"
                  rows={4}
                  placeholder="Description"
                />
              </div>
            {
              id?"":  <div className="form-group mb-5  ">
                
              <Field
                as="select"
                name="fournisseur"
                id="markselect"
                class="form-select"
              >
              <option value="">Fournisseur :</option>

                {providers.map((provider: any, index) => {
                  return (
                    <option key={index} value={provider.id}>
                      {provider.name} &ensp;{provider.fullname}

                    </option>
                  );
                })}
              </Field>
            </div>
            }
              <div className="row">
                <div className="form-group mb-5  col ">
                
                  <Field
                    as="select"
                    name="categorie"
                    id="markselect"
                    class="form-select"
                  >
                    <option value="">Categorie</option>
                    {categories.map((categorie: any, index) => {
                      return (
                        <option key={index} value={categorie.id}>
                          {categorie.titre}
                        </option>
                      );
                    })}
                  </Field>
                </div>
                <div className="form-group mb-5 col ">
                
                  <select
                    name="marque"
                    id="markselect"
                    className="form-select"
                    onChange={(e: any) => {
                      getModel(e.target.value);
                    }}
                  >
                    <option value="">Marque</option>
                    {marques.map((marque: any, index) => {
                      return (
                        <option key={index} value={marque.id}>
                          {marque.marque}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="form-group mb-5 col ">
               
                  <Field
                    as="select"
                    name="model"
                    id="markselect"
                    className="form-select"
                  >
                    <option value="">Model</option>
                    {models.map((model: any, index) => {
                      return (
                        <option key={index} value={model.id}>
                          {model.model}
                        </option>
                      );
                    })}
                  </Field>
                </div>
              </div>

              <div className="row">
                <div className="form-group mb-5 col">
                  <label className="mb-1" htmlFor="inputAddress">
                    Année model :
                  </label>
                  <Field
                    type="number"
                    name="annee"
                    className="form-control"
                    id="inputAddress"
                    placeholder="Année model"
                  />
                </div>
                <div className="form-group mb-5 col">
                  <label className="mb-1" htmlFor="inputAddress2">
                    Puissance :
                  </label>
                  <Field
                    type="text"
                    name="puissance"
                    className="form-control"
                    id="inputAddress2"
                    placeholder="Puissance"
                  />
                </div>

                <div className="form-group mb-5 col ">
                  <label className="mb-1" htmlFor="prix">
                    Prix :
                  </label>
                  <Field
                    type="number"
                    name="prix"
                    className="form-control"
                    id="prix"
                    placeholder="Prix  en $"
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group mb-5 col">
                 
                  <select
                    value={selectedCountry}
                    name={"pays"}
                    className="form-select"
                    onChange={(e) => {
                      setSelectedCountry(e.target.value);
                      getCitiesByCountry(e.target.value);
                    }}
                  >
                    <option value="">Pays</option>
                    {countries.map((country: any, index) => (
                      <option key={index} value={country.id}>
                        {country.pays}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group mb-5 col">
                
                  <Field as="select" name={"ville"} className="form-select">
                    <option value="">Ville</option>
                    {cities.map((city: any, index) => (
                      <option key={index} value={city.id}>
                        {city.ville}
                      </option>
                    ))}
                  </Field>
                </div>
              </div>
              <div className="row">
                <div className="form-group mb-5 col">
                  <label className="mb-1 " htmlFor="etat">
                    Slug:
                  </label>
                  <Field
                    type="text"
                    name="slug"
                    className="form-control"
                    id="etat"
                    placeholder="Slug"
                  />{" "}
                </div>
              </div>
              <div className="row">
                <div className="form-group mb-5 col">
                  <label className="mb-1 " htmlFor="etat">
                    Etat de Machine :
                  </label>
                  <Field
                    type="text"
                    name="etat"
                    className="form-control"
                    id="etat"
                    placeholder="Etat de Machine "
                  />{" "}
                </div>
              </div>
              <div className="d-flex justify-content-end ">
              {addloding ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    En cours ...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary text-end">
                    Ajouter
                  </button>
                )}
            </div>
  
          </Form></>
           
        )}
      </Formik>
    </>
  );
};

export default AjouterMachine;
