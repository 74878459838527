import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import React, { ChangeEvent, useState,useEffect } from 'react';
import { toast } from 'react-toastify';
import { Loading } from '../Loading';

const ModalAddModels = () => {
  const [image, setImage] = useState<File | any>(null);
  const [file,setFile]=useState<string>("");
  const initialValues = {
    model: "",
    description: "",
    image: "",
    marque: "",
    dateCreation: "",
  };
  const [marques, setMarques] = useState([]);
   const [loading, setLoading] = useState(false)


  const handelSumbit=(values:any)=>{
    console.log(values);
        setLoading(true)
    const formData = new FormData();
    formData.append('image', image);
    formData.append("marque",values['marque'])
    formData.append("description",values['description'])
    formData.append("dateCreation",values['dateCreation'])
    formData.append("model",values['model'])


      axios.post("https://api.machinery.fcpo.ma/api/models",formData).then(()=>{
        console.log("");
        setLoading(false)
        toast.success("bien ajouté ")

        
      }).catch((error)=>{
        toast.error(error.response.data.detail)
        setLoading(false)

      })
  }
  useEffect(() => {
    axios.get("https://api.machinery.fcpo.ma/api/marques.json")
      .then((response) => {
        setMarques(response.data); 

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  
console.log(marques);


  return (

<div className="modal fade" id="addmodel" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div className="modal-dialog" role="document">

  <Formik
      initialValues={initialValues}
      onSubmit={(values)=>{handelSumbit(values)}}>
        {()=>( 

    <Form className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Add Model</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

      </div>
      <div className="modal-body">
          <form>
          <label htmlFor="exampleInputEmail1">Photo :</label>    
    <div className="form-group mb-5  ">
      <div className="image-input image-input-outline" data-kt-image-input="true" style={{backgroundImage: 'url(/assets/media/svg/avatars/blank.svg)'}}>
      <div id="image" className="image-input-wrapper w-125px h-125px" style={{backgroundImage: file==""? "url('')" :"url("+file+")"}}>
      
    </div>
      <label className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
        data-kt-image-input-action="change"
        data-bs-toggle="tooltip"
        data-bs-dismiss="click"
        title="Change avatar">
        <i className="bi bi-pencil-fill fs-7"></i>

        <input onChange={(e:ChangeEvent<HTMLInputElement>)=>{

            // const reader = new FileReader();
            // reader.onload=function(e){
            //   setUrl(e.target?.result)
            // }
            // reader.readAsDataURL(e.target.files[0])
            if (e.target.files && e.target.files[0]) {
              setFile(URL.createObjectURL(e.target.files[0]));   
              setImage(e.target.files[0]);
                          
            }
          }}
          type="file" name="image"  accept=".png, .jpg, .jpeg" />
          <input type="hidden" name="avatar_remove" />
      </label>

      <span className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
        data-kt-image-input-action="cancel"
        data-bs-toggle="tooltip"
        data-bs-dismiss="click"
        title="Cancel avatar">
          <i className="bi bi-x fs-2"></i>
      </span>

  </div>
    </div>
    <div className="form-group">
        <label htmlFor="markselect">Model :</label>
        <Field type="text"  name='model' className="form-control" id="exampleInputEmail1" aria-describedby="text" placeholder="Model" />

      </div>
      <div className="form-group">
        <label htmlFor="markselect">Marque :</label>
        <Field as="select" name="marque" id="markselect"class="form-select">
          <option value="">Marque</option>
              {
                marques.map((marque:any,index)=>{
                    return <option key={index} value={marque.id}>{marque.marque}</option>
                })
              }
        </Field>
      </div>


      <div className="form-group">
        <label htmlFor="exampleInputEmail1">Description :</label>
      <Field as="textarea" rows={2} name='description' className="form-control" id="exampleInputEmail1" aria-describedby="text" placeholder="Description" />
      </div>
  
    </form>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary">
          {
        loading?<Loading/>:"Ajouter"
        
        }
        </button>
      </div>

      </Form>)}
    </Formik>
  </div>
</div>

  )
}

export default ModalAddModels 