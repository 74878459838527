import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { FeedsWidget2, FeedsWidget3, FeedsWidget5 } from '../../../_metronic/partials/widgets'
import { HeaderMachine } from './HeaderMachine'
import { MachineItem } from './MachineItem'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from 'react-router-dom'
import { Loading } from '../Loading'
const ListMachine = () => {
 

    const {id}=useParams();

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: 'Machine Management',
      path: '/pages/managementmachines/listmachine',
      isSeparator: false,
      isActive: false,
    }
  ]

 const [machines, setMachines] = useState([])
 const [loading, setLoading] = useState(true)
 
 const deleteMachine=(id:number)=>{
  if (window.confirm("voulez-vous vraimenet supprimer la machine !")) {
    axios.delete(" https://api.machinery.fcpo.ma/api/machines/"+id)
    .then(()=>{
      const filteredmachines = machines.filter((machine:any )=> machine.id !== id);
      setMachines(filteredmachines);
      toast.success("machine supprimée");
       })
    .catch((error) => {
      console.error(error);
    });
  } 
}
const getMachines=()=>{
  if(id){
    axios.get("https://api.machinery.fcpo.ma/api/machines.json?fournisseur="+id)
  .then((response) => {
    setMachines(response.data);
    setLoading(false)
  })
  .catch((error) => {
    console.error(error);
  });
  }else{
    axios.get("https://api.machinery.fcpo.ma/api/machines.json")
  .then((response) => {
    setMachines(response.data);
    setLoading(false)
  })
  .catch((error) => {
    console.error(error);
  });
  }
}
 useEffect(() => {
  getMachines()
 }, [])

 const searchFilter=(category:any,model:any,marque:any)=>{
 console.log(category,model,marque);

 setLoading(true)

 axios
 .get("https://api.machinery.fcpo.ma/api/machines?category="+category+"&model="+model+"&marque="+marque)
 .then((response) => {
   setMachines(response.data);
   setLoading(false);
 })
 .catch((error) => {
   console.error(error);
 });

 
}
 
  return (
    <div>
      <HeaderMachine searching={searchFilter} show={true}/> 
      <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des machines</PageTitle>
    
    {
      loading ? 
     <Loading/>
    :
      <div className="row">

    {
      
      machines.length==0?
         <div>notFount</div>
      :
      machines.map((machine,index)=>{
        return <div  key={index} className="col-sm-6 mb-3">
        <MachineItem deletem={deleteMachine} data={machine} className='' />
        </div>
      })
    }
    
 
    
  </div>
}
      
    
    </div>
  )
}

export default ListMachine
