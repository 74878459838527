import axios from "axios";
import React, { ChangeEvent, useState,useEffect } from "react";
import { toast } from "react-toastify";
import { KTSVG } from "../../../_metronic/helpers";
import { Loading } from "../Loading";

type Props = {
  data: any;
  deletePays:(id:number)=>void
};

const ItemPays: React.FC<Props> = ({ data,deletePays }) => {
  const [file, setFile] = useState<string>("");
  const [change, setChange] = useState(false);
  const [pays, setPays] = useState(data.pays);
  const [drapeau, setDrapeau] = useState(data.imageurl);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPays(data.pays);
    setDrapeau(data.imageurl);
  }, [data]);

 
  const updatePays = (id: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", drapeau);
    formData.append("pays",pays);
console.log(data);


    axios
      .post("https://api.machinery.fcpo.ma/api/pays/" + id, formData)
      .then((response) => {
        toast.success("Pays modifié");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <div className="symbol symbol-45px me-5">
            {change ? (
              <img src={file} alt="" />
            ) : (
              <img src={"https://api.machinery.fcpo.ma" + drapeau} alt="" />
            )}
            <label
              className="btn btn-icon btn-circle btn-color-muted  w-25px h-25px bg-body shadow"
              data-kt-image-input-action="change"
              data-bs-toggle="tooltip"
              data-bs-dismiss="click"
              title="Change avatar"
            >
              <i className="bi bi-pencil-fill fs-7 ml-3"></i>

              <input
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files && e.target.files[0]) {
                    setFile(URL.createObjectURL(e.target.files[0]));
                    setDrapeau(e.target.files[0]);
                    setChange(true);
                  }
                }}
                type="file"
                name="image"
                accept=".png, .jpg, .jpeg"
              />
            </label>
          </div>
          <div className="d-flex justify-content-start flex-column">
            <input className="d-none" type="text" value={data.id} />

            <input
              className="text-dark fw-bold  d-block fs-6"
              type="text"
              value={pays}
              onChange={(e) => {
                setPays(e.target.value);
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex justify-content-end flex-shrink-0">
          <button
            type={"submit"}
            title="save"
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            onClick={() => updatePays(data.id)}
          >
            {loading ? (
              <Loading />
            ) : (
              <KTSVG
                path="/media/icons/duotune/general/gen005.svg"
                className="svg-icon-3"
              />
            )}
          </button>
          <button
            title="drop"
            className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm"
            onClick={() => deletePays(data.id)}

          >
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default ItemPays;
