
import { Pagination } from "@mui/material";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-bootstrap";
import { toast } from "react-toastify";
import { KTSVG } from "../../../_metronic/helpers";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { UsersListPagination } from "../../modules/apps/user-management/users-list/components/pagination/UsersListPagination";
import { HeaderModels } from "../managementModels/HeaderModels";
import SearchInput from "../SearchInput";
import ItemPays from "./ItemPays";
import ModalAddPays from "./ModalAddPays";

const ListPays = () => {
  const [pays, setPays] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const getPays =async () => {
    fetch("https://api.machinery.fcpo.ma/api/pays.jsonld?page="+currentPage)
    .then((res) => res.json())
    .then((data) =>{
  
      setPays(data['hydra:member']);
      setTotalItems(data['hydra:totalItems']);
      setTotalPages(Math.ceil(totalItems / 3)); // assuming 3 items per page  
      setLoading(false);
    } )
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getPays();
    
  }, [currentPage, totalItems]);

  const deletePays = (id: number) => {
    if (window.confirm("Voulez-vous vraiment supprimer ce pays !")) {
      axios
        .delete("https://api.machinery.fcpo.ma/api/pays/" + id)
        .then(() => {
          const filteredpays = pays.filter(
            (pay: any) => pay.id !== id
          );
          setPays(filteredpays);
          toast.success("Pays supprimé");
        })  
        .catch((error) => {
          console.error(error);
        });
    }
  };
  
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Pays",
      path: "/pages/managementGlobal/listpays",
      isSeparator: false,
      isActive: false,
    },
  ];
  function handlePageChange(event:any, value:any) {
    setCurrentPage(value);

  }
  const searching = (query: string) => {
    if(query===""){
     
      getPays()

    }else{
      setLoading(true);
      axios
        .get(
          "https://api.machinery.fcpo.ma/api/pays.json?pays=" + query
        )
        .then((response) => {
          console.log(response.data);
            setPays(response.data)
            setLoading(false);
        });
    }
      
  };
  return (
    <>
      <HeaderModels />
      <PageTitle breadcrumbs={usersBreadcrumbs}>liste Pays </PageTitle>
      <ToastContainer />

      <div className={`card }`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">
             <SearchInput searching={searching} placeholder="Pays"/>
            </span>
          </h3>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a user"
          >
            <button
              type="button"
              className="btn btn-sm btn-primary "
              data-toggle="modal"
              data-target="#modaladdpays"
            >
              <KTSVG
                path="/media/icons/duotune/general/gen035.svg"
                className="svg-icon-2"
              />
              Nouveau Pays
            </button>
            <ModalAddPays/>
          </div>
        </div>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="card-body py-3">
            {/* begin::Table container */}
            <div className="table-responsive">
              {/* begin::Table */}
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                {/* begin::Table head */}
                <thead>
                  <tr className="fw-bold text-muted">
                    <th className="min-w-150px">Pays</th>
                    <th className="min-w-100px text-end">Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  { pays.map((pay: any, index: any) => {
                    return <ItemPays key={index} data={pay}  deletePays={deletePays}
                    />;
                  })}
                </tbody>
                {/* end::Table body */}
              </table>
              <Pagination count={totalPages} page={currentPage} color="primary" onChange={handlePageChange}  className="d-flex justify-content-center"/>
              {/* end::Table */}
            </div>
            {/* end::Table container */}
          </div>
        )}
        {/* end::Header */}
        {/* begin::Body */}

        {/* begin::Body */}
      </div>
    </>
  );
};

export default ListPays;
