import React, {useEffect, useState}from 'react';
import { KTSVG } from '../../../_metronic/helpers';
import { MarkItem } from './MarkItem';
import ModalAddMark from './ModalAddMark';
import axios from 'axios';
import { HeaderMark } from './HeaderMark';
import { ToastContainer, toast } from "react-toastify";
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { Search } from '../../../_metronic/partials';
import SearchInput from '../SearchInput';

type Props = {
  className: string
  data:any
}

const ListMark = () => {
  const [marques, setMarques] = useState([])
  const [loading, setLoading] = useState(true);
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Gestion Des Marques",
      path: "/pages/managementGlobal/listcategories",
      isSeparator: false,
      isActive: false,
    },
  ];

  const getMarques=()=>{
    axios.get("https://api.machinery.fcpo.ma/api/marques.json")
    .then((response) => {
      setMarques(response.data);
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  const deleteMarque=(id:number)=>{
    if (window.confirm("Voulez-vous vraiment supprimer cette catégorie !")) {
      axios
        .delete("https://api.machinery.fcpo.ma/api/marques/" + id)
        .then(() => {
          const filteredmodels = marques.filter((model: any) => model.id !== id);
          setMarques(filteredmodels);
          toast.success("Marque supprimée");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  useEffect(() => {
    getMarques()
  }, [])
  const searching = (query: string) => {
    if(query===""){
     
      getMarques()

    }else{
      setLoading(true);
      axios
        .get(
          "https://api.machinery.fcpo.ma/api/marques.json?marque=" + query
        )
        .then((response) => {
          console.log(response.data);
            setMarques(response.data)
            setLoading(false);
        });
    }
      
  };
  return (
    <div>
    <HeaderMark />
    <PageTitle breadcrumbs={usersBreadcrumbs}>liste Marques </PageTitle>

    <ToastContainer />

    <div className={`card `}>

{/* begin::Header */}
<div className='card-header border-0 pt-5'>
  <h3 className='card-title align-items-start flex-column'>
    <span className='card-label fw-bold fs-3 mb-1'>
             <SearchInput placeholder='Marque' searching={searching}  />
    </span>
  </h3>
  <div
    className='card-toolbar'
    data-bs-toggle='tooltip'
    data-bs-placement='top'
    data-bs-trigger='hover'
    title='Click to add a user'
  >
   <button
              type="button"
              className="btn btn-sm btn-primary "
              data-toggle="modal"
              data-target="#modaladdmarque"
            >
              <KTSVG
                path="/media/icons/duotune/general/gen035.svg"
                className="svg-icon-2"
              />
              Nouvelle marque
            </button>

      
  <ModalAddMark/>  

  </div>
</div>
{
  loading?
  <div className="d-flex justify-content-center">
  <div className="spinner-border" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
</div>:
<div className='card-body py-3'>
  {/* begin::Table container */}
  <div className='table-responsive'>
    {/* begin::Table */}
    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
      {/* begin::Table head */}
      <thead>

        <tr className='fw-bold text-muted'>
          
          <th className='min-w-150px'>Marques</th>
          <th className='min-w-100px'>Description</th>
          <th className='min-w-100px'>Date de Creation</th>
          <th className='min-w-100px text-end'>Actions</th>
        </tr>
      </thead>
      {/* end::Table head */}
      {/* begin::Table body */}
      <tbody>
            {
          marques.map((marque, index)=>{
            return   <MarkItem  deleteMarque={deleteMarque} key={index} data={marque} className='' />
          })
        }
      </tbody>
            {/* end::Table body */}
          </table> 
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
}
{/* end::Header */}
{/* begin::Body */}

      {/* begin::Body */}
    
    </div>
    </div>
  )
}

export default ListMark 