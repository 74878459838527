import axios from "axios";
import { Field, Form, Formik, validateYupSchema } from "formik";
import React, { ChangeEvent, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { Loading } from "../Loading";

const ModalAddCategory = () => {
  const [image, setImage] = useState<File | any>(null);
  const [file, setFile] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const initialValues = {
    categorie: "",
    image: "",
    titre: "",
    description: "",
    dateCreation: "",
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().max(40, "Trops longue").required("champs vide !"),
    titre: Yup.string().required("champs vide !"),
  });

  const handelSumbit = (values: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", image);

    formData.append("titre", values["titre"]);
    formData.append("description", values["description"]);
    formData.append("dateCreation", values["dateCreation"]);

    console.log(values);
    axios
      .post("https://api.machinery.fcpo.ma/api/categories", formData)
      .then((response) => {
      
          setLoading(false);
          toast.success("categorie ajoutée");
          
      }).catch((error)=>{
        toast.error(error.response.data.detail);
        setLoading(false);

        
      });
  };
  return (
    <>
      <ToastContainer />
      <div
        className="modal fade"
        id="modaladdcategorie"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handelSumbit(values);
            }}
          >
            {({ errors, touched }) => (
              <Form className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Add category
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={()=>setLoading(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <label htmlFor="exampleInputEmail1">Photo :</label>
                    <div className="form-group mb-5  ">
                      <div
                        className="image-input image-input-outline"
                        data-kt-image-input="true"
                        style={{
                          backgroundImage:
                            "url(/assets/media/svg/avatars/blank.svg)",
                        }}
                      >
                        <div
                          id="image"
                          className="image-input-wrapper w-125px h-125px"
                          style={{
                            backgroundImage:
                              file == "" ? "url('')" : "url(" + file + ")",
                          }}
                        ></div>
                        <label
                          className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          data-bs-dismiss="click"
                          title="Change avatar"
                        >
                          <i className="bi bi-pencil-fill fs-7"></i>

                          <input
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              // const reader = new FileReader();
                              // reader.onload=function(e){
                              //   setUrl(e.target?.result)
                              // }
                              // reader.readAsDataURL(e.target.files[0])
                              if (e.target.files && e.target.files[0]) {
                                setFile(URL.createObjectURL(e.target.files[0]));
                                setImage(e.target.files[0]);
                              }
                            }}
                            type="file"
                            name="image"
                            accept=".png, .jpg, .jpeg"
                            required
                          />
                          <input type="hidden" name="avatar_remove" />
                        </label>

                        <span
                          className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="cancel"
                          data-bs-toggle="tooltip"
                          data-bs-dismiss="click"
                          title="Cancel avatar"
                        >
                          <i className="bi bi-x fs-2"></i>
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Category :</label>
                      <Field
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        name="titre"
                        aria-describedby="text"
                        placeholder="Category"
                      />
                      {errors.titre && touched.titre ? (
                        <div className="text-danger fw-bold">
                          {errors.titre}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Description :</label>
                      <Field
                        as="textarea"
                        rows={2}
                        className="form-control input-error"
                        id="exampleInputEmail1"
                        name="description"
                        aria-describedby="text"
                        placeholder="Description"
                      />
                      {errors.description && touched.description ? (
                        <div className="text-danger fw-bold">
                          {errors.description}
                        </div>
                      ) : null}
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Annuler
                  </button>

                  <button type="submit" className="btn btn-primary">
                    {loading ? <Loading /> : "Enregistrer"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ModalAddCategory;
