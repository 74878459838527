/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { ToastContainer, toast } from "react-toastify";
import ModalAddCategory from "./ModalAddCategory";
import "../../css/stylelistcategories.css";
import moment from "moment";
import axios from "axios";
import { Loading } from "../Loading";
type Props = {
  className: string;
  data: any;
  deleteCategorie: (id: number) => void;
};

const CategoryItem: React.FC<Props> = ({
  className,
  data,
  deleteCategorie,
}) => {
  const [categorie, setCategorie] = useState(data.titre);
  const [description, setDescription] = useState<string>(data.description);
  const [image, setImage] = useState(data.imageurl);
  const [date, setDate] = useState<any>(
    moment(data.dateCreation).format("YYYY-MM-DD")
  );
  const [id, setId] = useState(data.id);
  const [file, setFile] = useState<string>("");
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const body = {
    categorie: categorie,
    description: description,
    dateCreation: date,
    image: image,
  };

  const updateCategorie = (id: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", image);
    formData.append("titre", body["categorie"]);
    formData.append("description", body["description"]);
    formData.append("dateCreation", body["dateCreation"]);
    console.log(body);

    axios
      .post("https://api.machinery.fcpo.ma/api/categories/" + id, formData)

      .then((response) => {
        toast.success("Categorie modifiée");
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  console.log(data);

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <div className="symbol symbol-45px me-5">
            {change ? (
              <img src={file} alt="" />
            ) : (
              <img src={"https://api.machinery.fcpo.ma" + image} alt="" />
            )}
            <label
              className="btn btn-icon btn-circle btn-color-muted  w-25px h-25px bg-body shadow"
              data-kt-image-input-action="change"
              data-bs-toggle="tooltip"
              data-bs-dismiss="click"
              title="Change avatar"
            >
              <i className="bi bi-pencil-fill fs-7 ml-3"></i>

              <input
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files && e.target.files[0]) {
                    setFile(URL.createObjectURL(e.target.files[0]));
                    setImage(e.target.files[0]);
                    setChange(true);
                  }
                }}
                type="file"
                name="image"
                accept=".png, .jpg, .jpeg"
              />
            </label>
          </div>
          <div className="d-flex justify-content-start flex-column">
            <input className="d-none" type="text" value={id} />

            <input
              className="text-dark fw-bold  d-block fs-6"
              type="text"
              value={categorie}
              onChange={(e) => {
                setCategorie(e.target.value);
              }}
             
            />
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <textarea
              className="text-dark fw-bold  d-block fs-6"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
           required
           />
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start flex-column">
            <input
              className="text-dark fw-bold  d-block fs-6"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex justify-content-end flex-shrink-0">
          <button
            type={"submit"}
            title="save"
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
            onClick={() => updateCategorie(id)}
          >
            {loading ? (
              <Loading />
            ) : (
              <KTSVG
                path="/media/icons/duotune/general/gen005.svg"
                className="svg-icon-3"
              />
            )}
          </button>

          <button
            title="drop"
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            onClick={() => deleteCategorie(id)}
          >
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
          </button>
        </div>
      </td>
    </tr>
  );
};

export { CategoryItem };
