import React from 'react'
import { KTSVG } from '../../_metronic/helpers'

type Props = {
    searching: (query:string)=>void,
    placeholder:string
  };
 const  SearchInput: React.FC<Props> =({searching,placeholder})=> {
  return (
    <>
         <div className="d-flex align-items-center position-relative me-4">
                <KTSVG
                  path="/media/icons/duotune/general/gen021.svg"
                  className="svg-icon-3 position-absolute ms-3"
                />
                <input
                  type="text"
                  id="kt_filter_search"
                  className="form-control form-control-white form-control-sm w-200px ps-9"
                  placeholder={placeholder}
                  onChange={(e)=>{searching(e.target.value)}}
                />
              </div>


    </>
  )
}

export default SearchInput