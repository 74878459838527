import React from "react";
import NlleMachine from './NlleMachine'



export function Machines() {
  return(

    <NlleMachine/>
  
    )
}
export default NlleMachine