import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import { toast, ToastContainer } from 'react-toastify'
import { Loading } from '../../../pages/Loading'
import { HeaderWrapper } from '../../../../_metronic/layout/components/header/HeaderWrapper'
import { HeaderUsers } from '../../../pages/ManagementUsers/HeaderUsers'
import { HeaderUsersItem } from '../../../pages/ManagementUsers/HeaderUsersItem'
import HeaderResetPassword from './HeaderResetPassword'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'

const initialValues = {
  oldpassword:'',
  newpassword: ''

}

const forgotPasswordSchema = Yup.object().shape({
  oldpassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  newpassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  
})
const usersBreadcrumbs: Array<PageLink> = [
  {
    title: "Profile",
    path: "/profile/reset-password",
    isSeparator: false,
    isActive: false,
  },
  {
      title: "modifier mot de passe",
      path: "/profile/reset-password",
      isSeparator: true,
      isActive: true,
    },
];

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
const [message, setMessage] = useState("")
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      console.log(values.oldpassword );
      
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.oldpassword,values.newpassword)
        .then((response) => {
            setHasErrors(false)
            setLoading(false);
            toast.success(response.data)
          })
          .catch((error) => {
            // toast.error(error.response.data.detail)
            setMessage(error.response.data.detail)
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <>

      <form
        className='form w-75 fv-plugins-bootstrap5 fv-plugins-framework m-auto'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Modifier mots de passe ?</h1>
          {/* end::Title */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
               {message}
            </div>
          </div>
        )}

       
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>l'ancien mot de passe </label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('oldpassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid bg-white',
              {'is-invalid': formik.touched.oldpassword && formik.errors.oldpassword},
              {
                'is-valid': formik.touched.oldpassword && !formik.errors.oldpassword,
              }
            )}
          />
          {formik.touched.oldpassword && formik.errors.oldpassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.oldpassword}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>le nouveau mot de passe</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('newpassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid bg-white',
              {'is-invalid': formik.touched.newpassword && formik.errors.newpassword},
              {
                'is-valid': formik.touched.newpassword && !formik.errors.newpassword,
              }
            )}
          />
          {formik.touched.newpassword && formik.errors.newpassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.newpassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            
            {loading ? (
             <Loading/>
            ):<span className='indicator-label'>Modifier</span>}
          </button>
          
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
