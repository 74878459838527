import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Select from 'react-select'
import NewPage from './NewPage'
import { TablesWidget10, TablesWidget11 } from '../../../_metronic/partials/widgets'







function NlleMachines() {

  // try{
  //   const response=axios.get(process.env.HOST_URL+'/marques')
  //   response === null ? console.log('aucune resultat') : console.log(response)
  // }catch (error){
  //
  //   console.error(error)
  // }
  return (
    <>
      <div className="modal fade" id="exampleModal"  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Modal Title</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <i aria-hidden="true" className="ki ki-close"></i>
                </button>
            </div>
            <div className="modal-body">
              
          {/* <ElemForm Label='Titre de la Machine' Type='text' placeHolder='Titre de la machine' />
          <ElemForm Label='Description' Type='textarea' placeHolder='Description' />
          <ElemForm Label='Année model' Type='number' placeHolder='Année du model' />
          <ElemForm Label='Puissance' Type='number' placeHolder='Puissance' />
          <ElemForm Label='Prix en $' Type='number' placeHolder='Prix en $' />
          <ElemForm Label='Etat de la machine' Type='text' placeHolder='Exemple : Neuf, au casion, recyclée, ...' />
          <ListMarques Label='Selectionner la marque' Type='select' placeHolder='Selectionner la marque' />
         */}
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary font-weight-bold">Save changes</button>
            </div>
        </div>
    </div>
</div>
    </>


  )

}

export default NlleMachines