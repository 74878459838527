import axios from "axios";
import React, { useEffect, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import { HeaderUsers } from "./HeaderUsers";
import ModalAddUser from "./ModalAddUser";
import ModalAddCategory from "./ModalAddUser";

import { UserItem } from "./UserItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { UsersListFilter } from "../../modules/apps/user-management/users-list/components/header/UsersListFilter";
import {
  FeedsWidget3,
  FeedsWidget4,
  FeedsWidget5,
  TablesWidget10,
} from "../../../_metronic/partials/widgets";
import { TablePagination } from "@mui/material";
import { useAuth } from "../../modules/auth";

type Props = {
  className: string;
};

const ListUsers: React.FC<Props> = ({ className }) => {
  const { currentUser } = useAuth();

  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Utilisateur Management",
      path: "/pages/managementUsers/listusers",
      isSeparator: false,
      isActive: false,
    },
  ];

  const [users, setUsers] = useState([]);
  const [file, setFile] = useState<string>("");
  const [loading, setLoading] = useState(true);

  const deleteUser = (id: number) => {
    if (window.confirm("voulez-vous vraimenet supprimer cet utilisateur !")) {
      toast.success("utilisateur supprimé");
      axios
        .delete(" https://api.machinery.fcpo.ma/api/users/" + id)
        .then(() => {
          const filteredusers = users.filter((user: any) => user.id !== id);
          setUsers(filteredusers);
         
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const getUsers = () => {
    axios
      .get("https://api.machinery.fcpo.ma/api/users.json")
      .then((response) => {
        // console.log(response.data);

        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  console.log(users);

  const searchingByRole = (role: any) => {
    if (role == "Roles") {
      getUsers();
    }
    console.log(role);
    setLoading(true);
    axios
      .get("https://api.machinery.fcpo.ma/api/users/byroles/" + role)
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <HeaderUsers update={false} searchuser={searchingByRole} />

      <ToastContainer />

      <div
        className="card-toolbar  d-flex justify-content-end mb-3"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to add a user"
        // style={{marginRight:" -37em"}}
      >
        <button
          type="button"
          className="btn btn-sm btn-primary "
          data-toggle="modal"
          data-target="#modaladduser"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen035.svg"
            className="svg-icon-2"
          />
          Nouveau utilisateur
        </button>

        <ModalAddUser />
      </div>
      <PageTitle breadcrumbs={usersBreadcrumbs}>list Utilisateurs </PageTitle>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="row">
          {" "}
          {users.map((user: any, index) => {
            return currentUser?.id === user.id ? (
              <></>
            ) : (
              <div key={index} className="col-sm-6 mb-3">
                <UserItem deleteUser={deleteUser} data={user} className="" />
              </div>
            );
          })}
        </div>
      )}
      {/* begin::Body */}
    </div>
  );
};

export default ListUsers;
