/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { KTSVG } from "../../../_metronic/helpers";
import FormUpdateMachine from "./FormUpdateMachine";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Props = {
  data: any;
  deletemachine: (data: number) => void;
};
export const DropDownActions: React.FC<Props> = ({ data, deletemachine }) => {
  console.log(data);

  const deleteMachine = () => {
    deletemachine(data);
  };
  return (
    <>
      <ToastContainer />
      <div className="btn-group">
        <button
          type="button"
          className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold "
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen024.svg"
            className="svg-icon-2"
          />
        </button>
        <div className="dropdown-menu">
          <Link
            title={"modifier"}
            to={"/pages/managementmachines/editmachine/" + data}
            className="dropdown-item p-2 text-hover-primary"
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
            <span> modidfier machine</span>
          </Link>
         
          
          <Link
            title={"ajouter details pour image"}
            to={"/pages/managementmachines/addimageswithdetails/" + data}
            className="dropdown-item p-2 text-hover-primary"
          >
            <KTSVG
              path="/media/icons/duotune/general/gen035.svg"
              className="svg-icon-2"
            />
            <span> ajouter images</span>
          </Link>

          <Link
            to={"#"}
            title={"supprimer"}
            className="dropdown-item p-2 text-hover-primary"
          >
            <KTSVG
              // امين
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
            <span onClick={() => deleteMachine()}> supprimer machine</span>
          </Link>
        </div>
      </div>

      {/* <div
        className="dropdown "
        style={{ width: "29px", marginRight: " -19px", marginTop: "-32px" }}
      >
        <button
          className="btn p-0 "
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <KTSVG
            path="/media/icons/duotune/general/gen024.svg"
            className="svg-icon-2"
          />
        </button>
        <div
          className="dropdown-menu  "
          style={{ marginLeft: "-79px" ,width:"12em"}}
          aria-labelledby="dropdownMenu2"
        >
          <div className="d-block">
            <div className="d-flex">
              <Link
                style={{width:"112px"}}
                title={"voir machine"}
                to={"/pages/managementmachines/detailmachine/" + data}
                className="btn dropdown-item btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 ml-2 mb-2"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen019.svg"
                  className="svg-icon-3"
                />
                   <span > détails machine</span>
                     
              </Link>
            </div>
            
            <div className="d-flex">
              <Link
               style={{width:"112px"}}
                title={"modifier"}
                to={"/pages/managementmachines/editmachine/" + data}
                className="btn dropdown-item btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 ml-2 mb-2"
              >
                <KTSVG
                  path="/media/icons/duotune/art/art005.svg"
                  className="svg-icon-3"
                />      
                <span > modidfier machine</span>

              </Link>
            </div>
            <div className="d-flex">
              <Link
               style={{width:"112px"}}
                title={"ajouter details pour image"}
                to={"/pages/managementmachines/addimageswithdetails/" + data}
                className="btn dropdown-item btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 ml-2 mb-2"
              >
                 <KTSVG
                path="/media/icons/duotune/general/gen035.svg"
                className="svg-icon-2"
          />    
                <span > ajouter images</span>

              </Link>
            </div>
            <div className="d-flex">
              <Link
                title={"supprimer"}
                className="dropdown-item p-2 text-hover-primary"
                onClick={() => deleteMachine()}
              >
                <KTSVG
                  // امين
                  path="/media/icons/duotune/general/gen027.svg"
                  className="svg-icon-3"
                />
                  <span > supprimer machine</span>

              </Link>
            </div>

          
          </div>
        </div>
      </div> */}
    </>
  );
};
