import axios from 'axios'
import {useIntl} from 'react-intl'
import { Link } from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import { StatisticsWidget5 } from '../../../_metronic/partials/widgets'
import { HeaderDashboar } from './HeaderDashboard'
import React, {useEffect, useState}from 'react';
import { Loading } from '../Loading'

type Props={
  nbrCteg:any,
  nbMarque:any,
  nbrModel:any,
  nbrUsers:any,
  nbrMachine:any
}

const DashboardPage:React.FC<Props> = ({nbrCteg,nbrModel,nbMarque,nbrUsers,nbrMachine}) => (
  
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8'>
          <Link
            to={'/pages/managementmachines/listmachine'}
            className='col-xl-4'
          >
      <div>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='/media/icons/duotune/technology/teh005.svg'
          color='body-white'
          iconColor='primary'
          title={nbrMachine?nbrMachine+' Machines':"... Machines"}
          description=''
          titleColor='gray-900'
          descriptionColor='gray-400'
        /> 
      </div> 
          </Link>
      
          <Link
            to={'/pages/managementusers/users'}
            className='col-xl-4'
          >
      <div>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='/media/icons/duotune/general/gen049.svg'
          color='primary'
          iconColor='white'
          title={nbrUsers?nbrUsers+' Utilisateurs':"... Utilisateurs"}
          description=''
          titleColor='white'
          descriptionColor='white'
        />
      </div>
          </Link>
      
          <Link
            to={'/pages/managementGlobal/listcategories'}
            className='col-xl-4'
          >
      <div>
        <StatisticsWidget5
          className='card-xl-stretch mb-5 mb-xl-8'
          svgIcon='/media/icons/duotune/arrows/arr070.svg'
          color='dark'
          iconColor='gray-100'
          title={nbrCteg?nbrCteg+' Categories':"... Catégories"}
          description=''
          titleColor='gray-100'
          descriptionColor='gray-100'
        />
      </div> 
          </Link>

      
     
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row g-5 g-xl-8'> */}
      {/* begin::Col */}
      {/* <div className='col-xl-4'>
        <ListsWidget1 className='card-xl-stretch mb-xl-8' />
      </div> */}
      {/* end::Col */}

      {/* begin::Col */}
      {/* <div className='col-xl-8'>
        <TablesWidget5 className='card-xl-stretch mb-5 mb-xl-8' />
      </div> */}
      {/* end::Col */}
    {/* </div> 
    {/* end::Row */}

    {/* begin::Row */}
    {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div>
      <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div>
      <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div>
    </div>  */}
    {/* end::Row */}

    {/* <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div> 
   
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
       
      </div> */}
    </div>
     {/* end::Row */}
  </>
)

const DashboardWrapper = () => {
  const intl = useIntl()
  const [nbrCtegories, setNbrCtegories] = useState<number>()
  const [nbrmarques, setNbrmarques] = useState<number>()
  const [nbrmodels, setNbrmodels] = useState<number>()
  const [nbrusers, setNbrusers] = useState<number>()
  const [nbrmachine, setNbrMachine] = useState<number>()



  
  useEffect(() => {
      
    axios.get("https://api.machinery.fcpo.ma/api/machines").then((response)=>{
      setNbrMachine(response.data.length)      
    })
    axios.get("https://api.machinery.fcpo.ma/api/users").then((response)=>{
  setNbrusers(response.data.length)      
})
  axios.get("https://api.machinery.fcpo.ma/api/categories").then((response)=>{
          setNbrCtegories(response.data.length)      
  })
//   axios.get("https://api.machinery.fcpo.ma/api/marques").then((response)=>{
//     setNbrmarques(response.data.length)      
// })
// axios.get("https://api.machinery.fcpo.ma/api/models").then((response)=>{
//   setNbrmodels(response.data.length)      
// })


 
  }, [])
  return (
    <>
    <HeaderDashboar/>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
     
      <DashboardPage nbrCteg={nbrCtegories} nbrModel={nbrmodels} nbMarque={nbrmarques} nbrUsers={nbrusers} nbrMachine={nbrmachine} />

    </>
  )
}

export {DashboardWrapper}
