import axios from 'axios';
import React,{useState} from 'react'
import { KTSVG } from '../../../_metronic/helpers';



type Props={
    image:any,
    deleteimages:(id:any)=>void
}
export const InputFilesUpdate:React.FC<Props> = ({ image,deleteimages})  => {
    const [filess, setFiles] = useState<File[]>([]);

    const updateImage=(files:any)=>{
      console.log(image.id,files[0]);
      const formdata=new FormData();
      formdata.append("image",files[0])
      axios.post("https://api.machinery.fcpo.ma/api/images_details_update/"+image.id,formdata).then((response) => { 
     alert(response.data)

    })
    .catch((error) => {
        alert(error);
    });
    }
  return (
<div className="form-group mb-5  ">
                  <div
                    className="image-input image-input-outline"
                    data-kt-image-input="true"
                    style={{
                      backgroundImage:
                        "url(/assets/media/svg/avatars/blank.svg)",
                    }}
                  >
                    <div className="d-flex">
                      {filess.length == 0
                        ? 
                              <div
                               
                                id="image"
                                className="image-input-wrapper w-125px h-125px mr-2 "
                                style={{
                                  backgroundImage:
                                    "url('https://api.machinery.fcpo.ma" +
                                    image.imageurl +
                                    "')",
                                }}
                              ></div>
                            
                        
                        : filess.map((file) => {
                            return (
                                <>
                                 <div
                                id="image"
                                className="image-input-wrapper w-125px h-125px "
                                style={{
                                  backgroundImage:
                                    "url(" + URL.createObjectURL(file) + ")",
                                }}
                              ></div>

                                </>

                            
                            );
                          })}
                    </div>

                    <label
                      className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Change avatar"
                    >
                      <i className="bi bi-pencil-fill fs-7 position-fixed"></i>

                      <input
                        onChange={ async(e )  => {
                          const { files } = e.target;
                          if (files) {
                            setFiles(Array.from(files));
                            
                              updateImage(Array.from(files))
                          }
                          
                        }}
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                      />

                    </label>

                    <label
                      className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="remove"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Change avatar"
                      onClick={()=>{
                     
                         deleteimages(image.id)
                      }}
                    >
<KTSVG
              path="/media/icons/duotune/general/gen034.svg"
              className="svg-icon-2"
            />
                     
                    </label>

                    <span
                      className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                      data-kt-image-input-action="cancel"
                      data-bs-toggle="tooltip"
                      data-bs-dismiss="click"
                      title="Cancel avatar"
                     
                    >
                      <i className="bi bi-x fs-2"></i>
                    </span>
                  </div></div>

  )
}
