import React from 'react'
import Carousell from './Carousell'
import { Dropdown1 } from '../../_metronic/partials'
import { KTSVG } from '../../_metronic/helpers'
import { BiTimeFive } from "react-icons/bi";
import { DropDownPubDown } from './DropDownPubDown';


type Props = {
    className: string
    chartColor: string
    chartHeight: string
    data:any
    deletePub:(id:number)=>void
  }
const ItemPubDown :React.FC<Props> = ({className, chartColor, chartHeight,data,deletePub}) => {
  return (
    <>
     <div className='col-xl-4'>
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='text-muted fw-semibold fs-7'><BiTimeFive/> 12-12-2022</span>
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
        
          <DropDownPubDown deletePub={deletePub} id={data.id}/>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        <div className='flex-grow-1'>
         <Carousell image={data}/>
        </div>

        <div className='pt-5'>
          <p className='text-center fs-6 pb-5 '>
            <span className='badge badge-light-success fs-8'>Priorité: {data.priorite}</span>&nbsp;
          </p>

         
        </div>
      </div>
      {/* end::Body */}
    </div>
    </div>
    </>
  )
}
export default  ItemPubDown