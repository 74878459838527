import React, { ChangeEvent, useEffect, useState } from "react";
import { PageLink, PageTitle } from "../../../_metronic/layout/core";
import { HeaderMachine } from "./HeaderMachine";
import { Field, Form, Formik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import DetailsImages from "./DetailsImages";
import { Loading } from "../Loading";

const AddDetailsImagesMachine = () => {
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: "Machine Management",
      path: "/pages/managementmachines/listmachine",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "liste machine",
      path: "/pages/managementmachines/listmachine",
      isSeparator: false,
      isActive: false,
    },
  ];
  const { id }:any = useParams();
  const navigate = useNavigate();

  const [addloding, setAddLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [filess, setFiles] = useState<File[]>([]);
  const [details, setDetails] = useState<any>([])
  const initialValues = {
    titre: "",
    description: "",
    soustitre:""
  };

  const handelSumbit = (values: any) => {
    setAddLoading(true)
    const formdata = new FormData();

    for (let i = 0; i < filess.length; i++) {
      formdata.append("images[]", filess[i]);
    }
    formdata.append("titre", values["titre"]);
    formdata.append("detail", values["description"]);
    formdata.append("soustitre", values["soustitre"]);
    formdata.append("machine", id);
    axios.post("https://api.machinery.fcpo.ma/api/details_images", formdata)
    .then((response) => { 
        setAddLoading(false)
        toast.success("Machine ajoutée ");

    })
    .catch((error) => {
        toast.error(error);
    });
  };
  
  const  getDetailsImages=(id:number)=>{
        setShow(true)
        axios.get("https://api.machinery.fcpo.ma/api/details_images/"+id+".json")
        .then((response)=>{
              setDetails(response.data);
              setShow(false)
              
        })
  }
  useEffect(() => {
    getDetailsImages(id)
  
  }, [])
  
  return (
    <>
      <HeaderMachine searching={()=>{}} show={false}/>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        Ajouter Details Pour Images{" "}
      </PageTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handelSumbit(values)}
      >
        {() => (
          <>
            <ToastContainer />

            <Form className="mx-5">
              <label htmlFor="exampleInputEmail1">Photo :</label>
              <div className="form-group mb-5  ">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                  style={{
                    backgroundImage: "url(/assets/media/svg/avatars/blank.svg)",
                  }}
                >
                  <div className="d-flex">
                    {filess.length == 0 ? (
                      <div
                        id="image"
                        className="image-input-wrapper w-125px h-125px"
                        style={{ backgroundImage: "url('')" }}
                      ></div>
                    ) : (
                      filess.map((file) => {
                        return (
                          <div
                            id="image"
                            className="image-input-wrapper w-125px h-125px mr-2"
                            style={{
                              backgroundImage:
                                "url(" + URL.createObjectURL(file) + ")",
                            }}
                          ></div>
                        );
                      })
                    )}
                  </div>

                  <label
                    className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="change"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    title="Change avatar"
                  >
                    <i className="bi bi-pencil-fill fs-7"></i>

                    <input
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        // const reader = new FileReader();
                        // reader.onload=function(e){
                        //   setUrl(e.target?.result)
                        // }
                        // reader.readAsDataURL(e.target.files[0])
                        const { files } = e.target;
                        if (files) {
                          setFiles(Array.from(files));
                        }
                      }}
                      type="file"
                      name="avatar"
                      accept=".png, .jpg, .jpeg"
                      multiple
                    />

                    <input type="hidden" name="avatar_remove" />
                  </label>

                  <span
                    className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                    data-kt-image-input-action="cancel"
                    data-bs-toggle="tooltip"
                    data-bs-dismiss="click"
                    title="Cancel avatar"
                  >
                    <i className="bi bi-x fs-2"></i>
                  </span>
                </div>
              </div>

              <div className="form-group mb-5  ">
                <label className="mb-1" htmlFor="titre">
                  Titre Principal :
                </label>
                <Field
                  type="text"
                  className="form-control"
                  name="titre"
                  id="titre"
                  placeholder="Titre de Machine"
                />
              </div>
              <div className="form-group mb-5  ">
                <label className="mb-1" htmlFor="titre">
                  Sous-Titre :
                </label>
                <Field
                  type="text"
                  className="form-control"
                  name="soustitre"
                  id="titre"
                  placeholder="Sous Titre"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label"
                >
                  Description :
                </label>
                <Field
                  as="textarea"
                  className="form-control"
                  name="description"
                  id="exampleFormControlTextarea1"
                  rows={4}
                  placeholder="Description"
                />
              </div>

              <div className="d-flex justify-content-end  mb-5">
                {addloding ? (
                  <button className="btn btn-primary" type="button" disabled>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    En cours ...
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary text-end">
                    Ajouter
                  </button>
                )}
              </div>
               
              {
                  show ?<Loading/>:<DetailsImages update={true} details={details}/>
              }
                
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default AddDetailsImagesMachine;
