import { lazy, FC, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import NlleMachine from "../../Components/Views/Machines/Machines";
import ListMachine from "../pages/managementmachines/ListMachine";


import ListMark from "../pages/managementMark/ListMark";

import FormUpdateMachine from "../pages/managementmachines/FormUpdateMachine";
import AjouterMachine from "../pages/managementmachines/AjouterMachine";
import FormDetailMachine from "../pages/managementmachines/FormDetailMachine";

import ListUsers from "../pages/ManagementUsers/ListUsers";
import ModalAddUser from "../pages/ManagementUsers/ModalAddUser";
import ListModels from "../pages/managementModels/ListModels";
import AddDetailsImagesMachine from "../pages/managementmachines/AddDetailsImagesMachine";
import DetailsUser from "../pages/ManagementUsers/DetailsUser";
import ModalAddCategory from "../pages/managementCategories/ModalAddCategory";
import ListCategory from "../pages/managementCategories/ListCategory";
import ListPays from "../pages/managementPays/ListPays";
import ListVilles from "../pages/managementVilles/ListVilles";
import { ForgotPassword } from "../modules/auth/components/ForgotPassword";
import AddPub1 from "../pages/PubDown/AddPubDown";
import ListPub1 from "../pages/PubDown/ListPubDown";
import ListPubRight from "../pages/PubRight/ListPubRight";
import AddPubRight from "../pages/PubRight/AddPubRight";
import ListPubSlider from "../pages/PubSlider/ListPubSlider";
import AddPubSlider from "../pages/PubSlider/AddPubSlider";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
 

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/machines" element={<NlleMachine />} />
        <Route
          path="/pages/managementmachines/listmachine"
          element={<ListMachine />}
        />
      
        <Route
          path="/pages/managementGlobal/listmodels"
          element={<ListModels />}
        />
        <Route
          path="/pages/managementGlobal/listmarques"
          element={<ListMark />}
        />
        <Route
          path="/pages/managementGlobal/add"
          element={<ModalAddCategory />}
        />
          <Route
          path="/pages/managementGlobal/listpays"
          element={<ListPays />}
        />
          <Route
          path="/pages/managementGlobal/listville"
          element={<ListVilles />}
        />
        <Route
          path="/pages/managementusers/detailsuser/:id"
          element={<DetailsUser />}
        />

       <Route
          path="/profile/:id"
          element={<DetailsUser />}
        />
           <Route path='/profile/reset-password' element={<ForgotPassword />} />

        <Route path="auth/machines" element={<NlleMachine />} />
        <Route
          path="/pages/managementmachines/listmachine"
          element={<ListMachine />}
        />
          <Route
          path="pages/managementmachines/listmachines/fournisseur=:id"
          element={<ListMachine />}
        />
        <Route
          path="/pages/managementGlobal/listcategories"
          element={<ListCategory  className=""/>}
        />
        
        
       <Route
          path="/pages/managementUsers/listusers"
          element={<ListUsers className=""/>}
        />
        
        <Route
          path="/pages/pub1/addpub1"
          element={<AddPub1 />}
        />
          <Route
          path="pages/pub1/listpub1"
          element={<ListPub1 />}
        />
  <Route
          path="/pages/pub2/listpub2"
          element={<ListPubRight/>}
        />
        <Route
          path="/pages/pub2/addpub2"
          element={<AddPubRight/>}
        />

          <Route
          path="/pages/pubslide/addslidepub"
          element={<AddPubSlider/>}
        />
        
      
        <Route
          path="/pages/pubslide/listslidepub"
          element={<ListPubSlider/>}
        />
        
        {/* <Route path='/userlist' element={<Users/>} /> */}
        {/* <Route path="/adduser" element={<AddUser />} /> */}
       



        <Route path="/dashboard" element={<DashboardWrapper />} />

        {/* Pages */}
        <Route
          path="/pages/managementmachines/editmachine/:id"
          element={<FormUpdateMachine />}
        />
        <Route
          path="/pages/managementmachines/ajoutermachine"
          element={<AjouterMachine />}
        />
         <Route
          path="/pages/managementmachines/ajoutermachine/:id"
          element={<AjouterMachine />}
        />
        <Route
          path="/pages/managementmachines/addimageswithdetails/:id"
          element={<AddDetailsImagesMachine />}
        />

        <Route
          path="/pages/managementmachines/detailmachine/:id"
          element={<FormDetailMachine />}
        />

        <Route
          path="builder"
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />

        <Route
          path="builder"
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path="menu-test" element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

      

        {/* Page Not Found */}
        {/* <Route path='*' element={<Navigate to='/error/404' />} /> */}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
