import React from "react";

type Props = {
  fournisseur: any;
  marque: any;
  model: any;
  categorie: any;
  puissance: any;
  etat: any;
  pays: any;
  ville: any;
  drapeau: any;
};
const CaracteristiquesMachine: React.FC<Props> = ({
  drapeau,
  fournisseur,
  puissance,
  categorie,
  model,
  marque,
  pays,
  ville,
  etat,
}) => {
  return (
    <table>
      <tbody>
        <tr>
          <th className="th-label">Fournisseur:</th>
          <td>{fournisseur}</td>
        </tr>
        <tr>
          <th className="th-label">Marque:</th>
          <td>{marque}</td>
        </tr>
        <tr>
          <th className="th-label">Model:</th>
          <td>{model}</td>
        </tr>
        <tr>
          <th className="th-label">Categorie:</th>
          <td>{categorie}</td>
        </tr>

        <tr>
          <th className="th-label"> Puissance:</th>
          <td>{puissance}</td>
        </tr>
        <tr>
          <th className="th-label"> Etat:</th>
          <td>{etat} </td>
        </tr>
        <tr>
          <th className="th-label">Pays- ville:</th>
          <td>
            <img
              src={"https://api.machinery.fcpo.ma" + drapeau}
              style={{ width: "30px", height: "18px", borderRadius: "2px" }}
            />
            &ensp;
            {pays} , {ville}
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default CaracteristiquesMachine;
