import * as React from 'react';
import { useTheme } from '@mui/material/styles';


import { CCarousel, CCarouselItem,CImage } from '@coreui/react';
// import { CImage } from '@coreui/react/dist/components';


const images = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bird',
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bali, Indonesia',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  },
  {
    label: 'Goč, Serbia',
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  },
];


type Props = {
  image:any
}
const Carousell:React.FC<Props> = ({image}) => {
 console.log(image);
 

  return (
    <CCarousel  controls>
    <CCarouselItem>
    <a target='_blank' href={image.lien1}>
      <CImage className="d-block w-100" src={"https://api.machinery.fcpo.ma/"+image.imageurl1} alt="slide 1" />
      </a>
    </CCarouselItem>
    <CCarouselItem>
      <a target='_blank' href={image.lien2}>
      <CImage className="d-block w-100" src={"https://api.machinery.fcpo.ma/"+image.imageurl2} alt="slide 1" />
      </a>
    </CCarouselItem>
    <CCarouselItem>
    <a target='_blank' href={image.lien3}>
      <CImage className="d-block w-100" src={"https://api.machinery.fcpo.ma/"+image.imageurl3} alt="slide 1" />
    </a>
    </CCarouselItem>
   
  </CCarousel>
  );
}

export default Carousell;